import React from 'react';
import { ProgressCircular, List, ListItem, Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';


class DocPhases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


        }

    }


    render() {
        return(
            <Card>
                <div className="content">
                <h2>Phases of Incident Response</h2>
                <p>Incident Response has a well defined lifecycle. It is helpful to use this lifecycle as a standard grammar for discussing activities and actions that might be performed during an incident.
                </p>
                <List>
                    <ListItem>
                        <div className="title">
                            <h4>Preperation</h4><br/>
                        </div>
                        <div className="content">
                            The preperation phase consists of everything we do to prevent, detect, or limit incidents and security events before they happen.
                            The key to the preperation phase is a strong security practice that meaningfully assess threats a risks, developers and implements strong policies, processes, and standards,
                            and enough time for a program of continious improvment. 
                        </div>
                    </ListItem>
                    <ListItem>
                        <div className="title"> 
                            <h4>Discover/Detect/Identify</h4>
                        </div>
                        <div className="content">
                            This phase represents the ability to actually detect a security event. This may be an alert from your security toolchain or a report of malicious or susipcious files or activity.
                            A strong detection phase is facilitated by a stable, reliable, and measurable security toolchain with the technical and procedrual controls to ensure events and alerts are properlly triaged. 
                        
                            Additionally, this phase also may involve manual discovery such as collecting IOCs or behavioural information for the purpose of scanning other infrastructure. 
                        </div>
                    </ListItem>
                    <ListItem>
                        <div className="title">
                           <h4>Containment</h4> 
                        </div>
                        <div className="content">
                            Once some malicious activity has been detected, the next step is to contain the activity. This may involve actions such as:
                           <ul>
                               <li>Disabling the networking on an infected machine.</li>
                               <li>Disabling accounts, revoking access, or forcing password resets.</li>
                               <li>Removing access tokens.</li>
                               <li>Resolving mis-configurations that may allow access.</li>
                               <li>Powering off systems.</li>
                           </ul>
                           The core objective of the containment phase is to prevent further impact from the security incident (Such as lateral traversal or data exfiltration). 
                           However, it is important to note that some containment activities may impact the ability to collect forensic information (For example, powering off a system means you can't do memory collection as that data is volitile.)
                        </div>
                    </ListItem>
                    <ListItem>
                        <div className="title"> 
                            <h4>Eradication</h4>
                        </div>
                        <div className="content">
                            During this phase actions are undertaken that will full remove the root cause, and all artefacts, of the incident from your network. This may include a number of actions such as:
                            <ul>
                                <li>Destroying a VM.</li>
                                <li>Cleaning malicious software.</li>
                                <li>Patching vulnerabilities that may be inital vectors or opportunities for lateral traversal.</li>
                                <li>Adding threat intellegence from the event into your security toolchain.</li>
                            </ul>
                            One critical thing to consider during this phase is that EDR solutions may clean part of an infection. Without a full forensic investigation of an attack from end to end that can 
                            identify every component and tool it is difficult to have confidence in your cleaning activities. It is almost always the right choice to destroy the resource and restore from a known good backup.
                            This is one of the reasons it's critical to abstract your backups from your dev/test/production workloads. 
                        </div>
                    </ListItem>
                    <ListItem>
                        <div className="title"> 
                            <h4>Recovery</h4>
                        </div>
                        <div className="content">
                            Return to service/Return to operation. During this phase known good backups are used to restore servers and resources, and in some cases, environments or systems need to be 
                            completely rebuilt from scratch. The key objective during this phase is to get the systems up and running with confidence that the incident will not reoccur, and all artefacts have been eradicated.
                        </div>
                    </ListItem>
                    <ListItem>
                        <div className="title"> 
                            <h4>Review</h4>
                        </div>
                        <div className="content">
                            <p>
                                This phase is a chance to learn from the incident. A formal incident review process should be part of your standard incident response plan. 
                            </p>
                        </div>
                    </ListItem>
                </List>
                </div>              
            </Card>
        );
    }


}

export default DocPhases