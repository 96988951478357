import React from 'react';
import { ProgressCircular, List, ListItem, Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class DocGlossary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


        }

    }


    render() {
        return(
            <Card>
                <div className="content">
                    <h2>Glossary of Terms</h2>
                    This is a glossary of common cybersecurity terms. 
                    It is drawn from the glossary available on the NICCS (NATIONAL INITIATIVE FOR CYBERSECURITY CAREERS AND STUDIES) <a href="https://niccs.cisa.gov/about-niccs/cybersecurity-glossary">website</a>.
                    <Tabs forceRenderTabPanel>
                        <TabList>
                            <Tab>A</Tab><Tab>B</Tab><Tab>C</Tab><Tab>D</Tab><Tab>E</Tab>
                            <Tab>F</Tab><Tab>G</Tab><Tab>H</Tab><Tab>I</Tab><Tab>J</Tab>
                            <Tab>K</Tab><Tab>L</Tab><Tab>M</Tab><Tab>N</Tab><Tab>O</Tab>
                            <Tab>P</Tab><Tab>Q</Tab><Tab>R</Tab><Tab>S</Tab><Tab>T</Tab>
                            <Tab>U</Tab><Tab>V</Tab><Tab>W</Tab><Tab>X</Tab><Tab>Y</Tab><Tab>Z</Tab>
                        </TabList>
                        <TabPanel>
                            <ul>
                                <li><b>Access</b> - The ability and means to communicate with or otherwise interact with a system, to use system resources to handle information, to gain knowledge of the information the system contains, or to control system components and functions.</li>
                                <li><b>access and identity management</b> - The methods and processes used to manage subjects and their authentication and authorizations to access specific objects.</li>
                                <li><b>access control</b> - The process of granting or denying specific requests for or attempts to: 1) obtain and use information and related information processing services; and 2) enter specific physical facilities.</li>
                                <li><b>access control mechanism</b> - Security measures designed to detect and deny unauthorized access and permit authorized access to an information system or a physical facility.</li>
                                <li><b>active attack</b> - An actual assault perpetrated by an intentional threat source that attempts to alter a system, its resources, its data, or its operations.</li>
                                <li><b>active content</b> - Software that is able to automatically carry out or trigger actions without the explicit intervention of a user.</li>
                                <li><b>Advanced Persistent Threat</b> - An adversary that possesses sophisticated levels of expertise and significant resources which allow it to create opportunities to achieve its objectives by using multiple attack vectors (e.g., cyber, physical, and deception).</li>
                                <li><b>adversary</b> - An individual, group, organization, or government that conducts or has the intent to conduct detrimental activities.</li>
                                <li><b>air gap</b> - To physically separate or isolate a system from other systems or networks (verb).</li>
                                <li><b>alert</b> - A notification that a specific attack has been detected or directed at an organization’s information systems.</li>
                                <li><b>Allowlist</b> - A list of entities that are considered trustworthy and are granted access or privileges.</li>
                                <li><b>All Source Intelligence</b> - In the NICE Framework, cybersecurity work where a person: Analyzes threat information from multiple sources, disciplines, and agencies across the Intelligence Community. Synthesizes and places intelligence information in context; draws insights about the possible implications.</li>
                                <li><b>Analyze</b> - A NICE Framework category consisting of specialty areas responsible for highly specialized review and evaluation of incoming cybersecurity information to determine its usefulness for intelligence.</li>
                                <li><b>antispyware software</b> - A program that specializes in detecting and blocking or removing forms of spyware.</li>
                                <li><b>antivirus software</b> - A program that monitors a computer or network to detect or identify major types of malicious code and to prevent or contain malware incidents. Sometimes by removing or neutralizing the malicious code.</li>
                                <li><b>asset</b> - A person, structure, facility, information, and records, information technology systems and resources, material, process, relationships, or reputation that has value.</li>
                                <li><b>asymmetric cryptography</b> - A branch of cryptography in which a cryptographic system or algorithms use two uniquely linked keys: a public key and a private key (a key pair).</li>
                                <li><b>attack</b> - An attempt to gain unauthorized access to system services, resources, or information, or an attempt to compromise system integrity.</li>
                                <li><b>attack method</b> - The manner or technique and means an adversary may use in an assault on information or an information system.</li>
                                <li><b>attack mode</b> - The manner or technique and means an adversary may use in an assault on information or an information system.</li>
                                <li><b>attack path</b> - The steps that an adversary takes or may take to plan, prepare for, and execute an attack.</li>
                                <li><b>attack pattern</b> - Similar cyber events or behaviors that may indicate an attack has occurred or is occurring, resulting in a security violation or a potential security violation.</li>
                                <li><b>attack signature</b> - A characteristic or distinctive pattern that can be searched for or that can be used in matching to previously identified attacks.</li>
                                <li><b>attack surface</b> - The set of ways in which an adversary can enter a system and potentially cause damage.</li>
                                <li><b>attacker</b> - An individual, group, organization, or government that executes an attack.</li>
                                <li><b>authenticate</b> - The process of verifying the identity or other attributes of an entity (user, process, or device).</li>
                                <li><b>authentication</b> - The process of verifying the identity or other attributes of an entity (user, process, or device).</li>
                                <li><b>authenticity</b> - A property achieved through cryptographic methods of being genuine and being able to be verified and trusted, resulting in confidence in the validity of a transmission, information or a message, or sender of information or a message.</li>
                                <li><b>authorization</b> - A process of determining, by evaluating applicable access control information, whether a subject is allowed to have the specified types of access to a particular resource.</li>
                                <li><b>availability</b> - The property of being accessible and usable upon demand.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>behavior monitoring</b> - Observing activities of users, information systems, and processes and measuring the activities against organizational policies and rule, baselines of normal activity, thresholds, and trends.</li>
                                <li><b>Blocklist</b> - A list of entities that are blocked or denied privileges or access.</li>
                                <li><b>Blue Team</b> - A group that defends an enterprise's information systems when mock attackers (i.e., the Red Team) attack, typically as part of an operational exercise conducted according to rules established and monitored by a neutral group (i.e., the White Team).</li>
                                <li><b>bot</b> - A computer connected to the Internet that has been surreptitiously / secretly compromised with malicious logic to perform activities under remote the command and control of a remote administrator.</li>
                                <li><b>bot herder</b> - The controller of a botnet that, from a remote location, provides direction to the compromised computers in the botnet.</li>
                                <li><b>bot master</b> - The controller of a botnet that, from a remote location, provides direction to the compromised computers in the botnet.</li>
                                <li><b>botnet</b> - A collection of computers compromised by malicious code and controlled across a network.</li>
                                <li><b>bug</b> - An unexpected and relatively small defect, fault, flaw, or imperfection in an information system or device.</li>
                                <li><b>Build Security In</b> - A set of principles, practices, and tools to design, develop, and evolve information systems and software that enhance resistance to vulnerabilities, flaws, and attacks.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>capability</b> - The means to accomplish a mission, function, or objective.</li>
                                <li><b>cipher</b> - cryptographic algorithm.</li>
                                <li><b>ciphertext</b> - Data or information in its encrypted form.</li>
                                <li><b>cloud computing</b> - A model for enabling on-demand network access to a shared pool of configurable computing capabilities or resources (e.g., networks, servers, storage, applications, and services) that can be rapidly provisioned and released with minimal management effort or service provider interaction.</li>
                                <li><b>Collect {'&'} Operate</b> - A NICE Framework category consisting of specialty areas responsible for specialized denial and deception operations and collection of cybersecurity information that may be used to develop intelligence.</li>
                                <li><b>Collection Operations</b> - In the NICE Framework, cybersecurity work where a person: Executes collection using appropriate strategies and within the priorities established through the collection management process.</li>
                                <li><b>computer forensics</b> - The processes and specialized techniques for gathering, retaining, and analyzing system-related data (digital evidence) for investigative purposes.</li>
                                <li><b>computer network defense</b> - The actions taken to defend against unauthorized activity within computer networks.</li>
                                <li><b>Computer Network Defense Analysis</b> - In the NICE Framework, cybersecurity work where a person: Uses defensive measures and information collected from a variety of sources to identify, analyze, and report events that occur or might occur within the network in order to protect information, information systems, and networks from threats.</li>
                                <li><b>Computer Network Defense Infrastructure Support</b> - In the NICE Framework, cybersecurity work where a person: Tests, implements, deploys, maintains, reviews, and administers the infrastructure hardware and software that are required to effectively manage the computer network defense service provider network and resources; monitors network to actively remediate unauthorized activities.</li>
                                <li><b>computer security incident</b> - An occurrence that actually or potentially results in adverse consequences to (adverse effects on) (poses a threat to) an information system or the information that the system processes, stores, or transmits and that may require a response action to mitigate the consequences.</li>
                                <li><b>confidentiality</b> - A property that information is not disclosed to users, processes, or devices unless they have been authorized to access the information.</li>
                                <li><b>consequence</b> - In cybersecurity, the effect of a loss of confidentiality, integrity or availability of information or an information system on an organization's operations, its assets, on individuals, other organizations, or on national interests.</li>
                                <li><b>Continuity of Operations Plan</b> - A document that sets forth procedures for the continued performance of core capabilities and critical operations during any disruption or potential disruption.</li>
                                <li><b>critical infrastructure</b> - The systems and assets, whether physical or virtual, so vital to society that the incapacity or destruction of such may have a debilitating impact on the security, economy, public health or safety, environment, or any combination of these matters.</li>
                                <li><b>critical infrastructure and key resources</b>- The systems and assets, whether physical or virtual, so vital to society that the incapacity or destruction of such may have a debilitating impact on the security, economy, public health or safety, environment, or any combination of these matters.</li>
                                <li><b>cryptanalysis</b> - The operations performed in defeating or circumventing cryptographic protection of information by applying mathematical techniques and without an initial knowledge of the key employed in providing the protection.</li>
                                <li><b>cryptographic algorithm</b> - A well-defined computational procedure that takes variable inputs, including a cryptographic key, and produces an output.</li>
                                <li><b>cryptography</b> - The use of mathematical techniques to provide security services, such as confidentiality, data integrity, entity authentication, and data origin authentication.</li>
                                <li><b>cryptology</b> - The mathematical science that deals with cryptanalysis and cryptography.</li>
                                <li><b>Customer Service and Technical Support</b> -  In the NICE Framework, cybersecurity work where a person: Addresses problems, installs, configures, troubleshoots, and provides maintenance and training in response to customer requirements or inquiries (e.g., tiered-level customer support).</li>
                                <li><b>cyber ecosystem</b> - The interconnected information infrastructure of interactions among persons, processes, data, and information and communications technologies, along with the environment and conditions that influence those interactions.</li>
                                <li><b>cyber exercise</b> - A planned event during which an organization simulates a cyber disruption to develop or test capabilities such as preventing, detecting, mitigating, responding to or recovering from the disruption.</li>
                                <li><b>cyber incident</b> - An occurrence that actually or potentially results in adverse consequences to (adverse effects on) (poses a threat to) an information system or the information that the system processes, stores, or transmits and that may require a response action to mitigate the consequences.</li>
                                <li><b>cyber incident response plan</b> - A set of predetermined and documented procedures to detect and respond to a cyber incident.</li>
                                <li><b>cyber infrastructure</b> - The information and communications systems and services composed of all hardware and software that process, store, and communicate information, or any combination of all of these elements: • Processing includes the creation, access, modification, and destruction of information. • Storage includes paper, magnetic, electronic, and all other media types. • Communications include sharing and distribution of information.</li>
                                <li><b>Cyber Operations</b> -  In the NICE Framework, cybersecurity work where a person: Performs activities to gather evidence on criminal or foreign intelligence entities in order to mitigate possible or real-time threats, protect against espionage or insider threats, foreign sabotage, international terrorist activities, or to support other intelligence activities.</li>
                                <li><b>Cyber Operations Planning</b> - in the NICE Framework, cybersecurity work where a person: Performs in-depth joint targeting and cyber planning process. Gathers information and develops detailed Operational Plans and Orders supporting requirements. Conducts strategic and operational-level planning across the full range of operations for integrated information and cyberspace operations</li>
                                <li><b>cybersecurity</b> - Strategy, policy, and standards regarding the security of and operations in cyberspace, and encompass[ing] the full range of threat reduction, vulnerability reduction, deterrence, international engagement, incident response, resiliency, and recovery policies and activities, including computer network operations, information assurance, law enforcement, diplomacy, military, and intelligence missions as they relate to the security and stability of the global information and communications infrastructure.</li>
                                <li><b>cyberspace</b> - The interdependent network of information technology infrastructures, that includes the Internet, telecommunications networks, computer systems, and embedded processors and controllers.</li>
                                <li><b>Cyber Threat Intelligence (CTI)</b> -  The collecting, processing, organizing, and analyzing data into actionable information that relates to capabilities, opportunities, actions, and intent of adversaries in the cyber domain to meet a specific requirement determined by and informing decision-makers.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>Data Administration</b> - In the NICE Framework, cybersecurity work where a person: Develops and administers databases and/or data management systems that allow for the storage, query, and utilization of data.</li>
                                <li><b>data aggregation</b> - The process of gathering and combining data from different sources, so that the combined data reveals new information.</li>
                                <li><b>data breach</b> - The unauthorized movement or disclosure of sensitive information to a party, usually outside the organization, that is not authorized to have or see the information.</li>
                                <li><b>data integrity</b> - The property that data is complete, intact, and trusted and has not been modified or destroyed in an unauthorized or accidental manner.</li>
                                <li><b>data leakage</b> - The unauthorized movement or disclosure of sensitive information to a party, usually outside the organization, that is not authorized to have or see the information.</li>
                                <li><b>data loss</b> - The result of unintentionally or accidentally deleting data, forgetting where it is stored, or exposure to an unauthorized party.</li>
                                <li><b>data loss prevention</b> - A set of procedures and mechanisms to stop sensitive data from leaving a security boundary.</li>
                                <li><b>data mining</b> - The process or techniques used to analyze large sets of existing information to discover previously unrevealed patterns or correlations.</li>
                                <li><b>data spill</b> - The unauthorized movement or disclosure of sensitive information to a party, usually outside the organization, that is not authorized to have or see the information.</li>
                                <li><b>data theft</b> - The deliberate or intentional act of stealing of information.</li>
                                <li><b>decipher</b> - To convert enciphered text to plain text by means of a cryptographic system.</li>
                                <li><b>decode</b> - To convert encoded text to plain text by means of a code.</li>
                                <li><b>decrypt</b> - A generic term encompassing decode and decipher.</li>
                                <li><b>decryption</b> - The process of transforming ciphertext into its original plaintext.</li>
                                <li><b>denial of service</b> - An attack that prevents or impairs the authorized use of information system resources or services.</li>
                                <li><b>designed-in security</b> - A set of principles, practices, and tools to design, develop, and evolve information systems and software that enhance resistance to vulnerabilities, flaws, and attacks.</li>
                                <li><b>digital forensics</b> - The processes and specialized techniques for gathering, retaining, and analyzing system-related data (digital evidence) for investigative purposes.</li>
                                <li><b>digital rights management</b> - A form of access control technology to protect and manage use of digital content or devices in accordance with the content or device provider's intentions.</li>
                                <li><b>digital signature</b> - A value computed with a cryptographic process using a private key and then appended to a data object, thereby digitally signing the data.</li>
                                <li><b>disruption</b> - An event which causes unplanned interruption in operations or functions for an unacceptable length of time.</li>
                                <li><b>distributed denial of service</b> - A denial of service technique that uses numerous systems to perform the attack simultaneously.</li>
                                <li><b>dynamic attack surface</b> - The automated, on-the-fly changes of an information system's characteristics to thwart actions of an adversary.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>Education and Training</b> - In the NICE Framework, cybersecurity work where a person: Conducts training of personnel within pertinent subject domain; develop, plan, coordinate, deliver, and/or evaluate training courses, methods, and techniques as appropriate.</li>
                                <li><b>electronic signature</b> - Any mark in electronic form associated with an electronic document, applied with the intent to sign the document.</li>
                                <li><b>encipher</b> - To convert plaintext to ciphertext by means of a cryptographic system.</li>
                                <li><b>encode</b> - To convert plaintext to ciphertext by means of a code.</li>
                                <li><b>encrypt</b> - The generic term encompassing encipher and encode.</li>
                                <li><b>encryption</b> - The process of transforming plaintext into ciphertext. Converting data into a form that cannot be easily understood by unauthorized people.</li>
                                <li><b>enterprise risk management</b> - A comprehensive approach to risk management that engages people, processes, and systems across an organization to improve the quality of decision making for managing risks that may hinder an organization’s ability to achieve its objectives.</li>
                                <li><b>event</b> - An observable occurrence in an information system or network.</li>
                                <li><b>exfiltration</b> - The unauthorized transfer of information from an information system.</li>
                                <li><b>exploit</b> - A technique to breach the security of a network or information system in violation of security policy.</li>
                                <li><b>Exploitation Analysis</b> -  In the NICE Framework, cybersecurity work where a person: Analyzes collected information to identify vulnerabilities and potential for exploitation.</li>
                                <li><b>exposure</b> - The condition of being unprotected, thereby allowing access to information or access to capabilities that an attacker can use to enter a system or network.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>Failure</b> - The inability of a system or component to perform its required functions within specified performance requirements.</li>
                                <li><b>firewall</b> - A hardware/software device or a software program that limits network traffic according to a set of rules of what access is and is not allowed or authorized.</li>
                                <li><b>forensics</b> - The processes and specialized techniques for gathering, retaining, and analyzing system-related data (digital evidence) for investigative purposes.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>hacker</b> - An unauthorized user who attempts to or gains access to an information system.</li>
                                <li><b>hash value</b> - A numeric value resulting from applying a mathematical algorithm against a set of data such as a file.</li>
                                <li><b>hashing</b> - A process of applying a mathematical algorithm against a set of data to produce a numeric value (a 'hash value') that represents the data.</li>
                                <li><b>hazard</b> - A natural or man-made source or cause of harm or difficulty.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>ICT supply chain threat</b> - A man-made threat achieved through exploitation of the information and communications technology (ICT) system’s supply chain, including acquisition processes.</li>
                                <li><b>identity and access management</b> - The methods and processes used to manage subjects and their authentication and authorizations to access specific objects.</li>
                                <li><b>impact</b> - In cybersecurity, the effect of a loss of confidentiality, integrity or availability of information or an information system on an organization's operations, its assets, on individuals, other organizations, or on national interests.</li>
                                <li><b>incident</b> - An occurrence that actually or potentially results in adverse consequences to (adverse effects on) (poses a threat to) an information system or the information that the system processes, stores, or transmits and that may require a response action to mitigate the consequences.</li>
                                <li><b>incident management</b> - The management and coordination of activities associated with an actual or potential occurrence of an event that may result in adverse consequences to information or information systems.</li>
                                <li><b>incident response</b> -  The activities that address the short-term, direct effects of an incident and may also support short-term recovery.</li>
                                <li><b>incident response plan</b> - A set of predetermined and documented procedures to detect and respond to a cyber incident.</li>
                                <li><b>indicator</b> - An occurrence or sign that an incident may have occurred or may be in progress.</li>
                                <li><b>Industrial Control System</b> - An information system used to control industrial processes such as manufacturing, product handling, production, and distribution or to control infrastructure assets.</li>
                                <li><b>information and communication(s) technology</b> - Any information technology, equipment, or interconnected system or subsystem of equipment that processes, transmits, receives, or interchanges data or information.</li>
                                <li><b>information assurance</b> - The measures that protect and defend information and information systems by ensuring their availability, integrity, and confidentiality.</li>
                                <li><b>Information Assurance Compliance</b> - In the NICE Framework, cybersecurity work where a person: Oversees, evaluates, and supports the documentation, validation, and accreditation processes necessary to assure that new IT systems meet the organization's information assurance and security requirements; ensures appropriate treatment of risk, compliance, and assurance from internal and external perspectives.</li>
                                <li><b>information security policy</b> - An aggregate of directives, regulations, rules, and practices that prescribe how an organization manages, protects, and distributes information.</li>
                                <li><b>information sharing</b> - An exchange of data, information, and/or knowledge to manage risks or respond to incidents.</li>
                                <li><b>information system resilience</b> - The ability of an information system to: (1) continue to operate under adverse conditions or stress, even if in a degraded or debilitated state, while maintaining essential operational capabilities; and (2) recover effectively in a timely manner.</li>
                                <li><b>Information Systems Security Operations</b> - In the NICE Framework, cybersecurity work where a person: Oversees the information assurance program of an information system in or outside the network environment; may include procurement duties (e.g., Information Systems Security Officer).</li>
                                <li><b>information technology</b> - Any equipment or interconnected system or subsystem of equipment that processes, transmits, receives, or interchanges data or information.</li>
                                <li><b>inside(r) threat</b> - A person or group of persons within an organization who pose a potential risk through violating security policies.</li>
                                <li><b>integrated risk management</b> - The structured approach that enables an enterprise or organization to share risk information and risk analysis and to synchronize independent yet complementary risk management strategies to unify efforts across the enterprise.</li>
                                <li><b>integrity</b> - The property whereby information, an information system, or a component of a system has not been modified or destroyed in an unauthorized manner.</li>
                                <li><b>intent</b> - A state of mind or desire to achieve an objective.</li>
                                <li><b>interoperability</b> - The ability of two or more systems or components to exchange information and to use the information that has been exchanged.</li>
                                <li><b>intrusion</b> - An unauthorized act of bypassing the security mechanisms of a network or information system.</li>
                                <li><b>intrusion detection</b> - The process and methods for analyzing information from networks and information systems to determine if a security breach or security violation has occurred.</li>
                                <li><b>Investigate</b> - a NICE Framework category consisting of specialty areas responsible for the investigation of cyber events and/or crimes of IT systems, networks, and digital evidence</li>
                                <li><b>investigation</b> - A systematic and formal inquiry into a qualified threat or incident using digital forensics and perhaps other traditional criminal inquiry techniques to determine the events that transpired and to collect evidence.</li>
                                <li><b>investigation</b> -  - A person, structure, facility, information, and records, information technology systems and resources, material, process, relationships, or reputation that has value.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>key</b> - The numerical value used to control cryptographic operations, such as decryption, encryption, signature generation, or signature verification.</li>
                                <li><b>key pair</b> - A public key and its corresponding private key.</li>
                                <li><b>key resource</b> - A publicly or privately controlled asset necessary to sustain continuity of government and/or economic operations, or an asset that is of great historical significance.</li>
                                <li><b>keylogger</b> - Software or hardware that tracks keystrokes and keyboard events, usually surreptitiously / secretly, to monitor actions by the user of an information system.</li>
                                <li><b>Knowledge Management</b> - In the NICE Framework, cybersecurity work where a person: Manages and administers processes and tools that enable the organization to identify, document, and access intellectual capital and information content.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>Legal Advice and Advocacy</b> - In the NICE Framework, cybersecurity work where a person: Provides legally sound advice and recommendations to leadership and staff on a variety of relevant topics within the pertinent subject domain; advocates legal and policy changes and makes a case on behalf of client via a wide range of written and oral work products, including legal briefs and proceedings.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>machine learning and evolution</b> - A field concerned with designing and developing artificial intelligence algorithms for automated knowledge discovery and innovation by information systems.</li>
                                <li><b>macro virus</b> - A type of malicious code that attaches itself to documents and uses the macro programming capabilities of the document’s application to execute, replicate, and spread or propagate itself.</li>
                                <li><b>malicious applet</b> - A small application program that is automatically downloaded and executed and that performs an unauthorized function on an information system.</li>
                                <li><b>malicious code</b> - Program code intended to perform an unauthorized function or process that will have adverse impact on the confidentiality, integrity, or availability of an information system.</li>
                                <li><b>malicious logic</b> - Hardware, firmware, or software that is intentionally included or inserted in a system to perform an unauthorized function or process that will have adverse impact on the confidentiality, integrity, or availability of an information system.</li>
                                <li><b>malware</b> - Software that compromises the operation of a system by performing an unauthorized function or process.</li>
                                <li><b>mitigation</b> - The application of one or more measures to reduce the likelihood of an unwanted occurrence and/or lessen its consequences.</li>
                                <li><b>moving target defense</b> - The application of one or more measures to reduce the likelihood of an unwanted occurrence and/or lessen its consequences.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>network resilience</b> - The ability of a network to: (1) provide continuous operation (i.e., highly resistant to disruption and able to operate in a degraded mode if damaged); (2) recover effectively if failure does occur; and (3) scale to meet rapid or unpredictable demands.</li>
                                <li><b>Network Services</b> - In the NICE Framework, cybersecurity work where a person: Installs, configures, tests, operates, maintains, and manages networks and their firewalls, including hardware (e.g., hubs, bridges, switches, multiplexers, routers, cables, proxy servers, and protective distributor systems) and software that permit the sharing and transmission of all spectrum transmissions of information to support the security of information and information systems.</li>
                                <li><b>non-repudiation</b> - A property achieved through cryptographic methods to protect against an individual or entity falsely denying having performed a particular action related to data.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>object</b> - A passive information system-related entity containing or receiving information.</li>
                                <li><b>Operate & Maintain</b> - A NICE Framework category consisting of specialty areas responsible for providing the support, administration, and maintenance necessary to ensure effective and efficient IT system performance and security.</li>
                                <li><b>operational exercise</b> - An action-based exercise where personnel rehearse reactions to an incident scenario, drawing on their understanding of plans and procedures, roles, and responsibilities.</li>
                                <li><b>Operations Technology</b> - The hardware and software systems used to operate industrial control devices.</li>
                                <li><b>outside( r) threat</b> - A person or group of persons external to an organization who are not authorized to access its assets and pose a potential risk to the organization and its assets.</li>
                                <li><b>Oversight & Development</b> - A NICE Framework category consisting of specialty areas providing leadership, management, direction, and/or development and advocacy so that all individuals and the organization may effectively conduct cybersecurity work.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>passive attack</b> - An actual assault perpetrated by an intentional threat source that attempts to learn or make use of information from a system, but does not attempt to alter the system, its resources, its data, or its operations.</li>
                                <li><b>password</b> - A string of characters (letters, numbers, and other symbols) used to authenticate an identity or to verify access authorization.</li>
                                <li><b>pen test</b> - A colloquial term for penetration test or penetration testing.</li>
                                <li><b>penetration</b> - An unauthorized act of bypassing the security mechanisms of a network or information system.</li>
                                <li><b>penetration testing</b> - An evaluation methodology whereby assessors search for vulnerabilities and attempt to circumvent the security features of a network and/or information system.</li>
                                <li><b>Personal Identifying Information / Personally Identifiable Information</b> - The information that permits the identity of an individual to be directly or indirectly inferred.</li>
                                <li><b>phishing</b> - A digital form of social engineering to deceive individuals into providing sensitive information.</li>
                                <li><b>plaintext</b> - Unencrypted information.</li>
                                <li><b>precursor</b> - An observable occurrence or sign that an attacker may be preparing to cause an incident.</li>
                                <li><b>Preparedness</b> - The activities to build, sustain, and improve readiness capabilities to prevent, protect against, respond to, and recover from natural or manmade incidents.</li>
                                <li><b>privacy</b> - The assurance that the confidentiality of, and access to, certain information about an entity is protected.</li>
                                <li><b>private key</b> - A cryptographic key that must be kept confidential and is used to enable the operation of an asymmetric (public key) cryptographic algorithm.</li>
                                <li><b>Protect & Defend</b> - A NICE Framework category consisting of specialty areas responsible for the identification, analysis, and mitigation of threats to internal IT systems or networks.</li>
                                <li><b>public key</b> - A cryptographic key that may be widely published and is used to enable the operation of an asymmetric (public key) cryptographic algorithm.</li>
                                <li><b>public key cryptography</b> - A branch of cryptography in which a cryptographic system or algorithms use two uniquely linked keys: a public key and a private key (a key pair).</li>
                                <li><b>public key encryption</b> - A branch of cryptography in which a cryptographic system or algorithms use two uniquely linked keys: a public key and a private key (a key pair).</li>
                                <li><b>Public Key Infrastructure</b> - A framework consisting of standards and services to enable secure, encrypted communication and authentication over potentially insecure networks such as the Internet.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>Recovery</b> - The activities after an incident or event to restore essential services and operations in the short and medium term and fully restore all capabilities in the longer term.</li>
                                <li><b>Red Team</b> - A group authorized and organized to emulate a potential adversary’s attack or exploitation capabilities against an enterprise’s cybersecurity posture.</li>
                                <li><b>Red Team exercise</b> - An exercise, reflecting real-world conditions, that is conducted as a simulated attempt by an adversary to attack or exploit vulnerabilities in an enterprise's information systems.</li>
                                <li><b>redundancy</b> - Additional or alternative systems, sub-systems, assets, or processes that maintain a degree of overall functionality in case of loss or failure of another system, sub-system, asset, or process.</li>
                                <li><b>resilience</b> - The ability to adapt to changing conditions and prepare for, withstand, and rapidly recover from disruption.</li>
                                <li><b>response</b> - The activities that address the short-term, direct effects of an incident and may also support short-term recovery.</li>
                                <li><b>response plan</b> - A set of predetermined and documented procedures to detect and respond to a cyber incident.</li>
                                <li><b>risk</b> - The potential for an unwanted or adverse outcome resulting from an incident, event, or occurrence, as determined by the likelihood that a particular threat will exploit a particular vulnerability, with the associated consequences.</li>
                                <li><b>risk analysis</b> - The systematic examination of the components and characteristics of risk.</li>
                                <li><b>risk assessment</b> - The product or process which collects information and assigns values to risks for the purpose of informing priorities, developing or comparing courses of action, and informing decision making.</li>
                                <li><b>risk management</b> -  The process of identifying, analyzing, assessing, and communicating risk and accepting, avoiding, transferring or controlling it to an acceptable level considering associated costs and benefits of any actions taken.</li>
                                <li><b>risk mitigation</b> - The application of one or more measures to reduce the likelihood of an unwanted occurrence and/or lessen its consequences.</li>
                                <li><b>risk-based data management</b> - A structured approach to managing risks to data and information by which an organization selects and applies appropriate security controls in compliance with policy and commensurate with the sensitivity and value of the data.</li>
                                <li><b>rootkit</b> - A set of software tools with administrator-level access privileges installed on an information system and designed to hide the presence of the tools, maintain the access privileges, and conceal the activities conducted by the tools.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>secret key</b> - A cryptographic key that is used for both encryption and decryption, enabling the operation of a symmetric key cryptography scheme.</li>
                                <li><b>Securely Provision</b> - A NICE Framework category consisting of specialty areas concerned with conceptualizing, designing, and building secure IT systems, with responsibility for some aspect of the systems' development.</li>
                                <li><b>security automation</b> - The use of information technology in place of manual processes for cyber incident response and management.</li>
                                <li><b>security incident</b> - An occurrence that actually or potentially results in adverse consequences to (adverse effects on) (poses a threat to) an information system or the information that the system processes, stores, or transmits and that may require a response action to mitigate the consequences.</li>
                                <li><b>security policy</b> - A rule or set of rules that govern the acceptable use of an organization's information and services to a level of acceptable risk and the means for protecting the organization's information assets.</li>
                                <li><b>Security Program Management</b> - In the NICE Framework, cybersecurity work where a person: Manages information security (e.g., information security) implications within the organization, specific program, or other area of responsibility, to include strategic, personnel, infrastructure, policy enforcement, emergency planning, security awareness, and other resources (e.g., the role of a Chief Information Security Officer).</li>
                                <li><b>signature</b> - A recognizable, distinguishing pattern.</li>
                                <li><b>situational awareness</b> - Comprehending information about the current and developing security posture and risks, based on information gathered, observation and analysis, and knowledge or experience.</li>
                                <li><b>software assurance</b> - The level of confidence that software is free from vulnerabilities, either intentionally designed into the software or accidentally inserted at any time during its lifecycle, and that the software functions in the intended manner.</li>
                                <li><b>Software Assurance and Security Engineering</b> - In the NICE Framework, cybersecurity work where a person: Develops and writes/codes new (or modifies existing) computer applications, software, or specialized utility programs following software assurance best practices.</li>
                                <li><b>spam</b> - The abuse of electronic messaging systems to indiscriminately send unsolicited bulk messages.</li>
                                <li><b>spillage</b> - The unauthorized movement or disclosure of sensitive information to a party, usually outside the organization, that is not authorized to have or see the information.</li>
                                <li><b>Spoofing</b> - Faking the sending address of a transmission to gain illegal [unauthorized] entry into a secure system.</li>
                                <li><b>spyware</b> - Software that is secretly or surreptitiously installed into an information system without the knowledge of the system user or owner.</li>
                                <li><b>Strategic Planning and Policy Development</b> - In the NICE Framework, cybersecurity work where a person: Applies knowledge of priorities to define an entity.</li>
                                <li><b>subject</b> - An individual, process, or device causing information to flow among objects or a change to the system state.</li>
                                <li><b>Supervisory Control and Data Acquisition</b> - A generic name for a computerized system that is capable of gathering and processing data and applying operational controls to geographically dispersed assets over long distances.</li>
                                <li><b>supply chain</b> - A system of organizations, people, activities, information and resources, for creating and moving products including product components and/or services from suppliers through to their customers.</li>
                                <li><b>Supply Chain Risk Management</b> - The process of identifying, analyzing, and assessing supply chain risk and accepting, avoiding, transferring or controlling it to an acceptable level considering associated costs and benefits of any actions taken.</li>
                                <li><b>symmetric cryptography</b> - A branch of cryptography in which a cryptographic system or algorithms use the same secret key (a shared secret key).</li>
                                <li><b>symmetric encryption algorithm</b> - A branch of cryptography in which a cryptographic system or algorithms use the same secret key (a shared secret key).</li>
                                <li><b>symmetric key</b> - A cryptographic key that is used to perform both the cryptographic operation and its inverse, for example to encrypt plaintext and decrypt ciphertext, or create a message authentication code and to verify the code.</li>
                                <li><b>System Administration</b> -  In the NICE Framework, cybersecurity work where a person: Installs, configures, troubleshoots, and maintains server configurations (hardware and software) to ensure their confidentiality, integrity, and availability; also manages accounts, firewalls, and patches; responsible for access control, passwords, and account creation and administration.</li>
                                <li><b>system integrity</b> - The attribute of an information system when it performs its intended function in an unimpaired manner, free from deliberate or inadvertent unauthorized manipulation of the system.</li>
                                <li><b>Systems Development</b> - In the NICE Framework, cybersecurity work where a person: Works on the development phases of the systems development lifecycle.</li>
                                <li><b>Systems Requirements Planning</b> - In the NICE Framework, cybersecurity work where a person: Consults with customers to gather and evaluate functional requirements and translates these requirements into technical solutions; provides guidance to customers about applicability of information systems to meet business needs.</li>
                                <li><b>Systems Security Analysis</b> - In the NICE Framework, cybersecurity work where a person: Conducts the integration/testing, operations, and maintenance of systems security.</li>
                                <li><b>Systems Security Architecture</b> - In the NICE Framework, cybersecurity work where a person: Develops system concepts and works on the capabilities phases of the systems development lifecycle; translates technology and environmental conditions (e.g., law and regulation) into system and security designs and processes.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>tabletop exercise</b> - A discussion-based exercise where personnel meet in a classroom setting or breakout groups and are presented with a scenario to validate the content of plans, procedures, policies, cooperative agreements or other information for managing an incident.</li>
                                <li><b>tailored trustworthy space</b> - A cyberspace environment that provides a user with confidence in its security, using automated mechanisms to ascertain security conditions and adjust the level of security based on the user's context and in the face of an evolving range of threats.</li>
                                <li><b>Targets</b> - In the NICE Framework, cybersecurity work where a person: Applies current knowledge of one or more regions, countries, non-state entities, and/or technologies.</li>
                                <li><b>Technology Research and Development</b> - In the NICE Framework, cybersecurity work where a person: Conducts technology assessment and integration processes; provides and supports a prototype capability and/or evaluates its utility.</li>
                                <li><b>Test and Evaluation</b> - In the NICE Framework, cybersecurity work where a person: Develops and conducts tests of systems to evaluate compliance with specifications and requirements by applying principles and methods for cost-effective planning, evaluating, verifying, and validating of technical, functional, and performance characteristics (including interoperability) of systems or elements of systems incorporating information technology.</li>
                                <li><b>threat</b> - A circumstance or event that has or indicates the potential to exploit vulnerabilities and to adversely impact (create adverse consequences for) organizational operations, organizational assets (including information and information systems), individuals, other organizations, or society.</li>
                                <li><b>threat actor</b> - An individual, group, organization, or government that conducts or has the intent to conduct detrimental activities.</li>
                                <li><b>threat agent</b> - An individual, group, organization, or government that conducts or has the intent to conduct detrimental activities.</li>
                                <li><b>threat analysis</b> - The detailed evaluation of the characteristics of individual threats.</li>
                                <li><b>threat assessment</b> - The product or process of identifying or evaluating entities, actions, or occurrences, whether natural or man-made, that have or indicate the potential to harm life, information, operations, and/or property.</li>
                                <li><b>ticket</b> - In access control, data that authenticates the identity of a client or a service and, together with a temporary encryption key (a session key), forms a credential.</li>
                                <li><b>traffic light protocol</b> - A set of designations employing four colors (RED, AMBER, GREEN, and WHITE) used to ensure that sensitive information is shared with the correct audience.</li>
                                <li><b>Trojan horse</b> - A computer program that appears to have a useful function, but also has a hidden and potentially malicious function that evades security mechanisms, sometimes by exploiting legitimate authorizations of a system entity that invokes the program.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>unauthorized access</b> - Any access that violates the stated security policy.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>virus</b> - A computer program that can replicate itself, infect a computer without permission or knowledge of the user, and then spread or propagate to another computer.</li>
                                <li><b>vulnerability</b> - A characteristic or specific weakness that renders an organization or asset (such as information or an information system) open to exploitation by a given threat or susceptible to a given hazard.</li>
                                <li><b>Vulnerability Assessment and Management </b> - In the NICE Framework, cybersecurity work where a person: Conducts assessments of threats and vulnerabilities, determines deviations from acceptable configurations, enterprise or local policy, assesses the level of risk, and develops and/or recommends appropriate mitigation countermeasures in operational and non-operational situations.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b>weakness</b> - A shortcoming or imperfection in software code, design, architecture, or deployment that, under proper conditions, could become a vulnerability or contribute to the introduction of vulnerabilities.</li>
                                <li><b>White Team</b> - A group responsible for refereeing an engagement between a Red Team of mock attackers and a Blue Team of actual defenders of information systems.</li>
                                <li><b>work factor</b> - An estimate of the effort or time needed by a potential adversary, with specified expertise and resources, to overcome a protective measure.</li>
                                <li><b>worm</b> - A self-replicating, self-propagating, self-contained program that uses networking mechanisms to spread itself.</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li><b></b></li>
                            </ul>
                        </TabPanel>
                    </Tabs>
                </div>     
            </Card>
        );
    }


}

export default DocGlossary