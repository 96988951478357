import React from 'react';

import { Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';

class LogoutButton extends React.Component{

    constructor(props) {
        super(props);
        this.user = props.user;
        this.gun = props.gun;

        this.state = {

        }
    }

    render() {
        return(
            <Button onClick={this.props.logoutHandler}><Icon icon="fa-sign-in-alt"/></Button>
        );
    }

}

export default LogoutButton;