const InitalVectorDeck = [
    {
        title : "Drive-by Compromise",
        mitre_id : "T1189",
        description : "Adversaries may gain access to a system through a user visiting a website over the normal course of browsing. With this technique, the user's web browser is typically targeted for exploitation, but adversaries may also use compromised websites for non-exploitation behavior such as acquiring Application Access Token.",
        detection : ["Access event monitoring"],
        prevention : ["MFA"],
        risk_factors : ["Credential Reuse"],
    },
    {
        title : "Exploit Public-Facing Application",
        mitre_id : "T1190",
        description : "Adversaries may attempt to take advantage of a weakness in an Internet-facing computer or program using software, data, or commands in order to cause unintended or unanticipated behavior. The weakness in the system can be a bug, a glitch, or a design vulnerability. These applications are often websites, but can include databases (like SQL), standard services (like SMB or SSH), network device administration and management protocols (like SNMP and Smart Install), and any other applications with Internet accessible open sockets, such as web servers and related services. Depending on the flaw being exploited this may include Exploitation for Defense Evasion.",
        detection : ["HIDS", "App Control Auditing"],
        prevention : ["WAF", "AV", "App AllowListing"],
        risk_factors : ["Insufficient patch management", "Dependancy Vulnerabilities"],
    },
    {
        title : "External Remote Services",
        mitre_id : "T1133",
        description : "Adversaries may leverage external-facing remote services to initially access and/or persist within a network. Remote services such as VPNs, Citrix, and other access mechanisms allow users to connect to internal enterprise network resources from external locations. There are often remote service gateways that manage connections and credential authentication for these services. Services such as Windows Remote Management and VNC can also be used externally.",
        detection : ["Access event monitoring", "location based anomaly detection"],
        prevention : ["MFA", "GeoIP ACLs", "Device compliance"],
        risk_factors : ["Legacy VPN", "Shadow IT"],
    },
    {
        title : "Hardware Additions",
        mitre_id : "T1200",
        description : "Adversaries may introduce computer accessories, computers, or networking hardware into a system or network that can be used as a vector to gain access. While public references of usage by threat actors are scarce, many red teams/penetration testers leverage hardware additions for initial access. Commercial and open source products can be leveraged with capabilities such as passive network tapping , network traffic modification (i.e. Adversary-in-the-Middle) , keystroke injection , kernel memory reading via DMA , addition of new wireless access to an existing network , and others.",
        detection : ["IDS"],
        prevention : ["IPS", "802.1x"],
        risk_factors : ["Insufficient physical security"],
    },
    {
        title : "Phishing",
        mitre_id : "T1566",
        description : "Adversaries may send phishing messages to gain access to victim systems. All forms of phishing are electronically delivered social engineering. Phishing can be targeted, known as spearphishing. In spearphishing, a specific individual, company, or industry will be targeted by the adversary. More generally, adversaries can conduct non-targeted phishing, such as in mass malware spam campaigns.",
        detection : ["User reporting"],
        prevention : ["EDR", "AntiSpam/AntiPhish", "Link/Attachment Scanning", "Security Training", "Phishing Testing"],
        risk_factors : ["OnPrem Email"],
    },
    {
        title : "Replication Through Removable Media",
        mitre_id : "T1091",
        description : "Adversaries may move onto systems, possibly those on disconnected or air-gapped networks, by copying malware to removable media and taking advantage of Autorun features when the media is inserted into a system and executes. In the case of Lateral Movement, this may occur through modification of executable files stored on removable media or by copying malware and renaming it to look like a legitimate file to trick users into executing it on a separate system. In the case of Initial Access, this may occur through manual manipulation of the media, modification of systems used to initially format the media, or modification to the media's firmware itself.",
        detection : ["Example Detection"],
        prevention : ["Example Prevention"],
        risk_factors : ["Example Risk Factors"],
    },
    {
        title : "Supply Chain Compromise",
        mitre_id : "T1195",
        description : "Adversaries may manipulate products or product delivery mechanisms prior to receipt by a final consumer for the purpose of data or system compromise.",
        detection : ["Example Detection"],
        prevention : ["Example Prevention"],
        risk_factors : ["Example Risk Factors"],
    },
    {
        title : "Trusted Relationship",
        mitre_id : "T1199",
        description : "Adversaries may breach or otherwise leverage organizations who have access to intended victims. Access through trusted third party relationship exploits an existing connection that may not be protected or receives less scrutiny than standard mechanisms of gaining access to a network.",
        detection : ["Example Detection"],
        prevention : ["Example Prevention"],
        risk_factors : ["Example Risk Factors"],
    },
    {
        title : "Valid Accounts",
        mitre_id : "T1078",
        description : "Adversaries may obtain and abuse credentials of existing accounts as a means of gaining Initial Access, Persistence, Privilege Escalation, or Defense Evasion. Compromised credentials may be used to bypass access controls placed on various resources on systems within the network and may even be used for persistent access to remote systems and externally available services, such as VPNs, Outlook Web Access and remote desktop. Compromised credentials may also grant an adversary increased privilege to specific systems or access to restricted areas of the network. Adversaries may choose not to use malware or tools in conjunction with the legitimate access those credentials provide to make it harder to detect their presence.",
        detection : ["Access event monitoring", "External data breach monitoring"],
        prevention : ["MFA", "Security Training"],
        risk_factors : ["Credential Reuse", "Overly complex password requirements"],
    },
]

const PersistanceDeck = [
    {
        title : "Account Manipulation",
        mitre_id : "T1098",
        description : "Adversaries may manipulate accounts to maintain access to victim systems. Account manipulation may consist of any action that preserves adversary access to a compromised account, such as modifying credentials or permission groups. These actions could also include account activity designed to subvert security policies, such as performing iterative password updates to bypass password duration policies and preserve the life of compromised credentials. In order to create or manipulate accounts, the adversary must already have sufficient permissions on systems or the domain.",
        detection : ["User Behaviour Monitoring"],
        prevention : ["Access Control", "Privileged Identity Management", "JIT Access"],
        risk_factors : ["Large numbers of administrators", "Weak access Control","Overly broad permissions"],
    },
    /*{
        title : "BITS Jobs",
        mitre_id : "T1197",
        description : "Adversaries may abuse BITS jobs to persistently execute or clean up after malicious payloads. Windows Background Intelligent Transfer Service (BITS) is a low-bandwidth, asynchronous file transfer mechanism exposed through Component Object Model (COM). BITS is commonly used by updaters, messengers, and other applications preferred to operate in the background (using available idle bandwidth) without interrupting other networked applications. File transfer tasks are implemented as BITS jobs, which contain a queue of one or more file operations.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },*/
    {
        title : "Boot or Logon Autostart Execution",
        mitre_id : "T1547",
        description : "Adversaries may configure system settings to automatically execute a program during system boot or logon to maintain persistence or gain higher-level privileges on compromised systems. Operating systems may have mechanisms for automatically running a program on system boot or account logon.  These mechanisms may include automatically executing programs that are placed in specially designated directories or are referenced by repositories that store configuration information, such as the Windows Registry. An adversary may achieve the same goal by modifying or extending features of the kernel.",
        detection : ["EDR", "App Allowlist Auditing"],
        prevention : ["App Control", "App Allowlist"],
        risk_factors : ["Unpatched Local PrivEsc Bugs", "Overly permissives local account policies"],
    },
    {
        title : "Boot or Logon Initialization Scripts",
        mitre_id : "T1037",
        description : "Adversaries may use scripts automatically executed at boot or logon initialization to establish persistence. Initialization scripts can be used to perform administrative functions, which may often execute other programs or send information to an internal logging server. These scripts can vary based on operating system and whether applied locally or remotely.",
        detection : ["XDR", "App Allowlist Auditing"],
        prevention : ["App Control/App Allowlist"],
        risk_factors : ["Unpatched Local PrivEsc Bugs", "Overly permissives local account policies"],
    },
    {
        title : "Browser Extensions",
        mitre_id : "T1176",
        description : "Adversaries may abuse Internet browser extensions to establish persistent access to victim systems. Browser extensions or plugins are small programs that can add functionality and customize aspects of Internet browsers. They can be installed directly or through a browser's app store and generally have access and permissions to everything that the browser can access.",
        detection : ["XDR", "Forensic Event Audits"],
        prevention : ["Browser Hardening"],
        risk_factors : ["Risky websites", "Unapproved use of company devices"],
    },
    {
        title : "Compromise Client Software Binary",
        mitre_id : "T1554",
        description : "Adversaries may modify client software binaries to establish persistent access to systems. Client software enables users to access services provided by a server. Common client software types are SSH clients, FTP clients, email clients, and web browsers.",
        detection : ["XDR/AV"],
        prevention : ["App Control/App Allowlist"],
        risk_factors : ["ShadowIT"],
    },
    {
        title : "Create Account",
        mitre_id : "T1136",
        description : "Adversaries may create an account to maintain access to victim systems. With a sufficient level of access, creating such accounts may be used to establish secondary credentialed access that do not require persistent remote access tools to be deployed on the system.",
        detection : ["User Behaviour Monitoring", "Directory (AD/AAD etc) Monitoring"],
        prevention : ["Access Control", "Least Privilege", "JIT Admin Access"],
        risk_factors : ["Weak Credentials", "Lack of MFA", "Overly permissive group memberships", "Too many administrators"],
    },
    {
        title : "Create or Modify System Process",
        mitre_id : "T1543",
        description : "Adversaries may create or modify system-level processes to repeatedly execute malicious payloads as part of persistence. When operating systems boot up, they can start processes that perform background system functions. On Windows and Linux, these system processes are referred to as services. On macOS, launchd processes known as Launch Daemon and Launch Agent are run to finish system initialization and load user specific parameters.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Event Triggered Execution",
        mitre_id : "T1546",
        description : "Adversaries may establish persistence and/or elevate privileges using system mechanisms that trigger execution based on specific events. Various operating systems have means to monitor and subscribe to events such as logons or other user activity such as running specific applications/binaries.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "External Remote Services",
        mitre_id : "T1133",
        description : "Adversaries may leverage external-facing remote services to initially access and/or persist within a network. Remote services such as VPNs, Citrix, and other access mechanisms allow users to connect to internal enterprise network resources from external locations. There are often remote service gateways that manage connections and credential authentication for these services. Services such as Windows Remote Management and VNC can also be used externally.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Hijack Execution Flow",
        mitre_id : "T1574",
        description : "	Adversaries may execute their own malicious payloads by hijacking the way operating systems run programs. Hijacking execution flow can be for the purposes of persistence, since this hijacked execution may reoccur over time. Adversaries may also use these mechanisms to elevate privileges or evade defenses, such as application control or other restrictions on execution.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Implant Internal Image",
        mitre_id : "T1525",
        description : "Adversaries may implant cloud or container images with malicious code to establish persistence after gaining access to an environment. Amazon Web Services (AWS) Amazon Machine Images (AMIs), Google Cloud Platform (GCP) Images, and Azure Images as well as popular container runtimes such as Docker can be implanted or backdoored. Unlike Upload Malware, this technique focuses on adversaries implanting an image in a registry within a victim’s environment. Depending on how the infrastructure is provisioned, this could provide persistent access if the infrastructure provisioning tool is instructed to always use the latest image.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Modify Authentication Process",
        mitre_id : "T1556",
        description : "Adversaries may modify authentication mechanisms and processes to access user credentials or enable otherwise unwarranted access to accounts. The authentication process is handled by mechanisms, such as the Local Security Authentication Server (LSASS) process and the Security Accounts Manager (SAM) on Windows, pluggable authentication modules (PAM) on Unix-based systems, and authorization plugins on MacOS systems, responsible for gathering, storing, and validating credentials. By modifying an authentication process, an adversary may be able to authenticate to a service or system without using Valid Accounts.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Office Application Startup",
        mitre_id : "T1137",
        description : "Adversaries may leverage Microsoft Office-based applications for persistence between startups. Microsoft Office is a fairly common application suite on Windows-based operating systems within an enterprise network. There are multiple mechanisms that can be used with Office for persistence when an Office-based application is started; this can include the use of Office Template Macros and add-ins.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Pre-OS Boot",
        mitre_id : "T1542",
        description : "Adversaries may abuse Pre-OS Boot mechanisms as a way to establish persistence on a system. During the booting process of a computer, firmware and various startup services are loaded before the operating system. These programs control flow of execution before the operating system takes control.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Scheduled Task/Job",
        mitre_id : "T1053",
        description : "Adversaries may abuse task scheduling functionality to facilitate initial or recurring execution of malicious code. Utilities exist within all major operating systems to schedule programs or scripts to be executed at a specified date and time. A task can also be scheduled on a remote system, provided the proper authentication is met (ex: RPC and file and printer sharing in Windows environments). Scheduling a task on a remote system typically requires being a member of an admin or otherwise privileged group on the remote system.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Server Software Component",
        mitre_id : "T1505",
        description : "Adversaries may abuse legitimate extensible development features of servers to establish persistent access to systems. Enterprise server applications may include features that allow developers to write and install software or scripts to extend the functionality of the main application. Adversaries may install malicious components to extend and abuse server applications.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Traffic Signaling",
        mitre_id : "T1205",
        description : "Adversaries may use traffic signaling to hide open ports or other malicious functionality used for persistence or command and control. Traffic signaling involves the use of a magic value or sequence that must be sent to a system to trigger a special response, such as opening a closed port or executing a malicious task. This may take the form of sending a series of packets with certain characteristics before a port will be opened that the adversary can use for command and control. Usually this series of packets consists of attempted connections to a predefined sequence of closed ports (i.e. Port Knocking), but can involve unusual flags, specific strings, or other unique characteristics. After the sequence is completed, opening a port may be accomplished by the host-based firewall, but could also be implemented by custom software.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
    {
        title : "Valid Accounts",
        mitre_id : "T1078",
        description : "Adversaries may obtain and abuse credentials of existing accounts as a means of gaining Initial Access, Persistence, Privilege Escalation, or Defense Evasion. Compromised credentials may be used to bypass access controls placed on various resources on systems within the network and may even be used for persistent access to remote systems and externally available services, such as VPNs, Outlook Web Access and remote desktop. Compromised credentials may also grant an adversary increased privilege to specific systems or access to restricted areas of the network. Adversaries may choose not to use malware or tools in conjunction with the legitimate access those credentials provide to make it harder to detect their presence.",
        detection : [""],
        prevention : [""],
        risk_factors : [""],
    },
]

const LateralTraversalDeck = [

    {
        title : "Admin credential capture",
        detection : ["EDR"],
        prevention : ["AV"],
        risk_factors : ["Out of data software"]
    },
    {
        title : "Exploitation of Remote Services",
        mitre_id : "T1210",
        description : "Adversaries may exploit remote services to gain unauthorized access to internal systems once inside of a network. Exploitation of a software vulnerability occurs when an adversary takes advantage of a programming error in a program, service, or within the operating system software or kernel itself to execute adversary-controlled code. A common goal for post-compromise exploitation of remote services is for lateral movement to enable access to a remote system.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Internal Spearphishing",
        mitre_id : "T1534",
        description : "Adversaries may use internal spearphishing to gain access to additional information or exploit other users within the same organization after they already have access to accounts or systems within the environment. Internal spearphishing is multi-staged attack where an email account is owned either by controlling the user's device with previously installed malware or by compromising the account credentials of the user. Adversaries attempt to take advantage of a trusted internal account to increase the likelihood of tricking the target into falling for the phish attempt.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Lateral Tool Transfer",
        mitre_id : "T1570",
        description : "Adversaries may transfer tools or other files between systems in a compromised environment. Files may be copied from one system to another to stage adversary tools or other files over the course of an operation. Adversaries may copy files laterally between internal victim systems to support lateral movement using inherent file sharing protocols such as file sharing over SMB to connected network shares or with authenticated connections with SMB/Windows Admin Shares or Remote Desktop Protocol. Files can also be copied over on Mac and Linux with native tools like scp, rsync, and sftp.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Remote Service Session Hijacking",
        mitre_id : "T1563",
        description : "Adversaries may take control of preexisting sessions with remote services to move laterally in an environment. Users may use valid credentials to log into a service specifically designed to accept remote connections, such as telnet, SSH, and RDP. When a user logs into a service, a session will be established that will allow them to maintain a continuous interaction with that service.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Remote Services",
        mitre_id : "T1021",
        description : "Adversaries may use Valid Accounts to log into a service specifically designed to accept remote connections, such as telnet, SSH, and VNC. The adversary may then perform actions as the logged-on user.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Replication Through Removable Media",
        mitre_id : "T1091",
        description : "Adversaries may move onto systems, possibly those on disconnected or air-gapped networks, by copying malware to removable media and taking advantage of Autorun features when the media is inserted into a system and executes. In the case of Lateral Movement, this may occur through modification of executable files stored on removable media or by copying malware and renaming it to look like a legitimate file to trick users into executing it on a separate system. In the case of Initial Access, this may occur through manual manipulation of the media, modification of systems used to initially format the media, or modification to the media's firmware itself.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Software Deployment Tools",
        mitre_id : "T1072",
        description : "Adversaries may gain access to and use third-party software suites installed within an enterprise network, such as administration, monitoring, and deployment systems, to move laterally through the network. Third-party applications and software deployment systems may be in use in the network environment for administration purposes (e.g., SCCM, HBSS, Altiris, etc.).",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Taint Shared Content",
        mitre_id : "T1080",
        description : "Adversaries may deliver payloads to remote systems by adding content to shared storage locations, such as network drives or internal code repositories. Content stored on network drives or in other shared locations may be tainted by adding malicious programs, scripts, or exploit code to otherwise valid files. Once a user opens the shared tainted content, the malicious portion can be executed to run the adversary's code on a remote system. Adversaries may use tainted shared content to move laterally.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Use Alternate Authentication Material",
        mitre_id : "T1550",
        description : "Adversaries may use alternate authentication material, such as password hashes, Kerberos tickets, and application access tokens, in order to move laterally within an environment and bypass normal system access controls.",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
]

const DiscoveryDeck = [
    {
        title : "Malware Detected and Cleaned",
        description : "Malware was detected on a server. The EDR solution was able to clean the detected infection, however this is not a guarantee that there is not additional undetected components.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Anomonlous User Login Behaviour",
        description : "An anomolous user behaviour alert has been triggered by a users authentication events. This may include access from new regions, or access from multiple physically distant regions.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Suspicious files",
        description : "A user or engineer has noticed some susipicous files on a server or workstation and reported it to the security team.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Internal IDS Alert",
        description : "A network IDS has detected potentially malicious network traffic between two internal devices.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "App Control violation",
        description : "Your application allowlisting has detected or prevented an unauthroized binary from executing.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Potentially malicious tools detected",
        description : "Your security toolchain has detected software on a device that may be part of an attackers post-compromise toolchain.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "User reports suspicious device behaviour",
        description : "A user has reported strange behaviour on a device such as files moving around or files that they delete immediatly being restored.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Endpoint protection disabled.",
        description : "Your security team has received an alert that something has disabled your EDR solution on a device.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "DLP violation.",
        description : "Your security team has recieved an alert that a user/device has attempted to exfiltrate information matching rules in your DLP (Data Leak Prevention) solution.",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
    {
        title : "Externally Reported.",
        description : "A customer, vendor, or other 3rd party has reported suspicious behaviour in your enviromnent such as defacement of your public website or malicious traffic coming from your IP space. ",
        mitre_id : "N/A",
        detection : [""],
        prevention : [""],
        risk_factors : [""]
    },
]

const goodEvents = [

    {
        type : 0,
        title : "IOCs located - Inital Vector",
        description : "A vendor you follow has released relevent IOCs that can detect the exploitation of the inital compromise vector."
    },
    {
        type : 0,
        title : "Too Old to Fail",
        description : "The attacker has been trying to pivot but their target software is so far out of date that the vulnerabilities they are trying do not work."
    },
    {
        type : 0,
        title : "Here comes the cavalry",
        description : "The organization has decided to fund an external forensics team to help respond."
    },
    {
        type : 0,
        title : "Internal threat intellegence.",
        description : "You have discovered a key IOC that is part of the attackers common toolchain, you are now able to scan any system and detect the attackers post-compromise toolchain reliabley."
    },

]

const badEvents = [

    {
        type : 1,
        title : "Lateral Traversal",
        description : "There has been some sort of lateral traversal. An adjacent system, network, or a system that shares users has been compromised."
    },
    {
        type : 1,
        title : "Data Exfil",
        description : "You find evidence that some data has been exfiltrated."
    },
    {
        type : 1,
        title : "Data located on pastebin",
        description : "Internal data is found on pastebin."
    },
    {
        type : 1,
        title : "Escalation point non-contactable",
        description : "Your primary escalation person is not available."
    },
    {
        type : 1,
        title : "Previously unknown PII",
        description : "You discover that there is a large amount of PII on an affected system that you didn't know about previously."
    },
    {
        type : 1,
        title : "The call is coming from inside the network.",
        description : "This threat is either internal, or has access to internal operational intellegence. They have access to information such as IPAM (IP address management), asset dbs, or topology diagrams."
    },
]


export {InitalVectorDeck, PersistanceDeck, LateralTraversalDeck, DiscoveryDeck, goodEvents, badEvents};