import React from 'react';
import { ProgressCircular, List, ListItem, Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';


class DocExternalResources extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


        }

    }


    render() {
        return(
            <Card>
                <div className="content">
                    <h2>External Resources</h2>
                    <p>
                        External resources that may be useful in planning your actions.
                    </p>
                    <List>
                        <ListItem>
                            <a href="https://www.nist.gov/privacy-framework/nist-sp-800-61">NIST 800-61 Computer Security Incident Handling Guide</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://www.cert.govt.nz/">CertNZ</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://www.sans.org/white-papers/33901/">SANS Incident Handler's Handbook</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://docs.microsoft.com/en-us/security/compass/incident-response-process">Microsoft Security Operations Incident Response Process</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://csrc.nist.gov/publications/detail/sp/800-181/rev-1/final">NIST NICE Framework</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://csrc.nist.gov/publications/detail/nistir/7298/rev-3/final">NIST Glossary of Key Information Security Terms</a>
                        </ListItem>
                    </List>
                </div>         
            </Card>
        );
    }


}

export default DocExternalResources