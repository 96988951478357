import React, {useState} from 'react';

import { Popover, Modal, Card, Page, Toolbar, Button, Fab, Icon, BackButton, ListHeader, List, ListItem } from 'react-onsenui';


const CardComponent = (props) => {
    let [desc_open, setDescOpen] = useState(false);

    let detections = [];
    let preventions = [];
    let risk_factors = [];

    if (Array.isArray(props.data.detection)) {
        detections = props.data.detection
    }
    if (Array.isArray(props.data.prevention)) {
        preventions = props.data.prevention
    }
    if (Array.isArray(props.data.risk_factors)) {
        risk_factors = props.data.risk_factors
    }


    let card_bg_colour = "rgba(242, 132, 130, 0.5)";
    let type = "Inital Access"
    if (props.type === 0) {
        card_bg_colour = "rgba(242, 132, 130, 0.5)";
        type = "Inital Access"
    } else if (props.type === 1) {
        card_bg_colour = "rgba(246, 189, 96, 0.5)";
        type = "Persistence"
    } else if (props.type === 2) {
        card_bg_colour = "rgba(132, 165, 157, 0.5)";
        type = "Lateral Traversal"
    } else if (props.type === 3) {
        card_bg_colour = "rgba(132, 165, 255, 0.5)";
        type = "Discovery"
    }

    let mitre_id = props.data.mitre_id;

    let btn_style = {
        backgroundColor : 'transparent',
    }

    let title = props.data.title;
    let description = props.data.description;

    const closeDesc = () => {
        setDescOpen(false);
    }

    const openDesc = () => {
        setDescOpen(true);
    }

    if (props.player) {
        if ("expert_mode" in props) {
            if (props.expert_mode) {
                title = "<REDACTED>";
                description = "<REDACTED>";
                mitre_id = "<REDACTED>";
                for (let i in detections) {
                    detections[i] = "<REDACTED>";
                }
                for (let i in preventions) {
                    preventions[i] = "<REDACTED>";
                }
                for (let i in risk_factors) {
                    risk_factors[i] = "<REDACTED>";
                } 
            }
        }
    }

    return(


        <Card className="card" style={{ width : 240, backgroundColor : card_bg_colour }}>
            <small>{type} {" "} {mitre_id}</small>
            <div className="title">
                {title}
                {(props.type === 0 || props.type === 1 || props.type === 2) && (
                    <>
                        {" "}<Button style={btn_style} onClick={openDesc}><Icon icon="fa-info-circle"/></Button>
                    </>
                )}
                
            </div>
            <div className="content">
                { (props.type === 0 || props.type === 1 || props.type === 2) && ( //Hacky hacky hack - This is so I can use the card component for discovery cards as well

                  
                        <List>
                            <ListHeader>Detection</ListHeader>
                            {detections.map( (val, i) => {
                                return(<ListItem style={{ color : "#a7c957" }}><small><i>{val}</i></small></ListItem>)
                            })}
                            <ListHeader>Prevention</ListHeader>
                            {preventions.map( (val, i) => {
                                return(<ListItem style={{ color : "#a7c957" }}><small><i>{val}</i></small></ListItem>)
                            })}
                            <ListHeader>Risk Factors</ListHeader>
                            {risk_factors.map( (val, i) => {
                                return(<ListItem style={{ color : "#bc4749" }}><small><i>{val}</i></small></ListItem>)
                            })}
                        </List>  

                


                )}

                {( props.type === 3) && (
                    <p>
                        {description}
                    </p>
                )}

            </div>
            <Modal
                isOpen={desc_open}>
                <div onClick={closeDesc} style={{textAlign: 'center', backgroundColor : card_bg_colour}}>
                <p>Description{" "}<Button style={btn_style} onClick={closeDesc}><Icon icon="fa-times-circle"/></Button></p>
                <p><small>{description}</small></p>
                </div>
            </Modal>
        </Card>
    )

}



export default CardComponent