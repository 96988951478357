







function getReportDefaults() {


    return {
        introduction : (<>
                    <h2>Introduction</h2>
                    <p>This report details the results of an incident response tabletop exercise performed using the Incident and Indicators tool.</p>
                </>),
        objective : (<>
                <h2>Objective</h2>
                <p>The primary objective of these exercises is to identify potential gaps or opportunities for improvement within 
                    a organisations Incident Response processes and to keep key stakeholders within the organistion up to date and 
                    current on the policies, processes, and standards in place to manage cybersecurity incidents.</p>
                </>),
        scenario : (<>
                        <h2>Scenario</h2>
                        <p>This exercise was performed against a scenario generated by InI using techniques identified by the 
                            MITRE ATT&CK® taxonomy. For the purposes of the scenario, the compromise or attak was detected via the 
                            following method: </p>                                
                </>),
        custom_narrative : (<>
                        <h4>Custom Scenario Details</h4>
                        <p>The following additional details were also provided by the team while developing the scenario: </p>   
                </>),





    }

}

export default getReportDefaults;