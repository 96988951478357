import React from 'react';

import { Select, CarouselItem, List, ListItem, ProgressCircular, Carousel, Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';
import CardComponent from '../cards/CardComponent';

import {InitalVectorDeck, PersistanceDeck, LateralTraversalDeck, DiscoveryDeck, goodEvents, badEvents} from '../cards/CardData';
import {sampleNarratives} from '../cards/SampleNarratives';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import './custom_modal.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './react-tabs.css';

import MatrixBackground from '../MatrixBackground';


import DocPhases from '../docs/DocsPhases';
import DocGlossary from '../docs/DocGlossary';
import DocExternalResources from '../docs/DocExternalResources';
import {getCardFromTitle, diceRoll} from '../cards/CardHelper';

import LogoutButton from '../LogoutButton.js';

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

import generateInIReport from './reportGenerator.js';

import getReportDefaults from './reportDefaults.js';

const IRBREIFING = 0;
const DRAWSCENARIO = 3;
const SCENARIOINTRO = 4;
const RESPONSETURN = 5;
const BETWEENTURNS = 6;
const EVENTTURN = 7;
const EVENTTURNDRAW = 8;
const GAMEOVER = 9;

const CLASS_GENERALIST = 0;
const CLASS_SIEM = 1;
const CLASS_DFIR = 2;
const CLASS_NETWORK = 3;
const CLASS_REVERSER = 4;
const CLASS_APP = 5;
const CLASS_TEAMLEADER = 6;


const PHASE_PREPARE = 1
const PHASE_DETECT = 2
const PHASE_CONTAIN = 3
const PHASE_ERADICATE = 4
const PHASE_RECOVER = 5
const PHASE_REVIEW = 6


function getSevName(sevid) {
    if (sevid == 1) {
        return "OFI";
    } else if (sevid == 2) {
        return "Minor";
    } else if (sevid == 3) {
        return "Major";
    }

    return "Unknown"
}


function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }


function getPhaseName(phaseid) {

    if (phaseid == PHASE_PREPARE) {
        return "PREPARE"
    } else if (phaseid == PHASE_DETECT) {
        return "DETECT/ASSES"
    } else if (phaseid == PHASE_CONTAIN) {
        return "CONTAIN"
    } else if (phaseid == PHASE_ERADICATE) {
        return "ERADICATE"
    } else if (phaseid == PHASE_RECOVER) {
        return "RECOVER"
    } else if (phaseid == PHASE_REVIEW) {
        return "REVIEW"
    }

    return "NOT FOUND"
}


function getPlayerClassName(class_id) {

    if (class_id == CLASS_GENERALIST) {
        return "Generalist";
    } else if (class_id == CLASS_SIEM) {
        return "SIEM Specialist";
    } else if (class_id == CLASS_DFIR) {
        return "DFIE Specialist";
    } else if (class_id == CLASS_NETWORK) {
        return "Network Specialist";
    } else if (class_id == CLASS_REVERSER) {
        return "Malware Researcher";
    } else if (class_id == CLASS_APP) {
        return "Application/SDL Specialist"
    } else if (class_id == CLASS_TEAMLEADER) {
        return "Team Leader"
    }

    return "Generalist";

}

const EventCardComponent = (props) => {

    let card_bg = "#386641"
    if (props.type === 0) {
        card_bg = "#386641"
    } else {
        card_bg = "#bc4749"
    }

    return(
        <Card className="card" style={{ width : 240, backgroundColor : card_bg }}>
            <div className="title">
                {props.title}
            </div>
            <div className="content">
                {props.description}
            </div>
        </Card>
    )

}


class HostMain extends React.Component{

    constructor(props) {
        super(props);
        this.user = props.user;
        this.introComplete = this.introComplete.bind(this);
        this.setIncidenOver = this.setIncidenOver.bind(this);
        this.setNextTurn = this.setNextTurn.bind(this);
        this.endResponseTurn = this.endResponseTurn.bind(this);
        this.triggerEvent = this.triggerEvent.bind(this);
        this.eventAcceptCallback = this.eventAcceptCallback.bind(this);
        this.getExample = this.getExample.bind(this);
        this.resourceModalClose = this.resourceModalClose.bind(this);
        this.showResourceModal = this.showResourceModal.bind(this);
        this.eventDenyCallback = this.eventDenyCallback.bind(this);
        this.nextEventCallback = this.nextEventCallback.bind(this);

        this.setCurrentPhase = this.setCurrentPhase.bind(this);
        this.onTurnNotesChange = this.onTurnNotesChange.bind(this);

        this.showAddGapModal = this.showAddGapModal.bind(this);
        this.onGapTextChange = this.onGapTextChange.bind(this);
        this.onGapPhaseChange = this.onGapPhaseChange.bind(this);
        this.onGapSeverityChange = this.onGapSeverityChange.bind(this);
        this.saveGap = this.saveGap.bind(this);
        this.closeGapModal = this.closeGapModal.bind(this);
        this.deleteGap = this.deleteGap.bind(this);

        this.drawNewCards = this.drawNewCards.bind(this);

        this.drawScenarioCards = this.drawScenarioCards.bind(this);

        this.saveDataToBrowserStorage = this.saveDataToBrowserStorage.bind(this);
        this.getSessionObject = this.getSessionObject.bind(this);

        this.onCustomNarrativeChange = this.onCustomNarrativeChange.bind(this);

        this.findingsBySeverity = this.findingsBySeverity.bind(this);
        this.findingsByPhase = this.findingsByPhase.bind(this);

        this.exportImage = this.exportImage.bind(this);
        this.exportPdf = this.exportPdf.bind(this);

        this.exportFindings = this.exportFindings.bind(this);
        this.exportTimeline = this.exportTimeline.bind(this);

        this.startExercise = this.startExercise.bind(this);
        

        console.log(props.user)

        this.state = {
            game_state : props.game_state,
            expert_mode : props.expert_mode,
            current_roll : 0,

            custom_narrative : "",

            //Add Gap stuff
            show_gap_modal : false,
            gap_text : "" ,
            gap_phase : 1,
            gap_severity : 1,

            //Host Cards
            //Scenario cards
            vector : null,
            expert_show_vector : false,
            persistance : null,
            expert_show_persistance : false,
            lateral_traversal : null,
            expert_show_lateral_traversal : false,
            discovery : null,
            //Event Cards
            event_cards : [],
            //Turn variables
            current_player_index : 0,
            last_player_roll : "",
            //EVent card vars
            active_events : [],
            event_modal_show : false,
            event_card : "",
            event_card_data : {
                title : "",
            },
            //Examples
            example_narrative : "",
            
            //Resource Modal
            resource_modal_show : false,

            current_phase : "1",
            turn_notes : "",
            turn_events : [],

            turn_data : [

            ],

            gap_data : [

            ]

        }
    }

    componentDidMount() {

        console.log("COMPONENT TO MOUNT")

        this.drawScenarioCards()

        this.setState({ game_state : IRBREIFING });

    }


    drawScenarioCards() {
        let vector = (InitalVectorDeck.sort(() => 0.5 - Math.random())).slice(0, 1)[0]
        let persistance = (PersistanceDeck.sort(() => 0.5 - Math.random())).slice(0, 1)[0]
        let lateral_traversal = (LateralTraversalDeck.sort(() => 0.5 - Math.random())).slice(0, 1)[0]
        let discovery = (DiscoveryDeck.sort(() => 0.5 - Math.random())).slice(0, 1)[0]
        this.setState({vector : vector })
        this.setState({persistance : persistance })
        this.setState({lateral_traversal : lateral_traversal })
        this.setState({discovery : discovery })

        this.saveDataToBrowserStorage()
    }

    introComplete() {

        this.setState({ game_state : RESPONSETURN });
    }

    setIncidenOver() {
        if (window.confirm("Are you sure you want to mark this incident as resolved?")) {
           this.setState({ game_state : GAMEOVER }); 
        }
        

    }


    startExercise() {
        this.setState({ game_state : SCENARIOINTRO });
    }


    setNextTurn() {
        this.setState({ game_state : RESPONSETURN });
    }

    onCustomNarrativeChange(e) {
        this.setState({ custom_narrative : e.target.value });
    }

    endResponseTurn() {
        let res =  diceRoll()
        if (res < 10) {
            this.setState({ has_good_luck : false });
        } else {
            this.setState({ has_good_luck : true });
        }
        this.setState({ current_roll : res });

        var tmp_data = {
            turn_phase : this.state.current_phase,
            turn_phase_desc : getPhaseName(this.state.current_phase),
            turn_notes : this.state.turn_notes,
            turn_events : this.state.turn_events
        }

        this.setState({ turn_events: []});
        this.setState({ turn_data: [...this.state.turn_data, tmp_data]});
        this.setState({ game_state : BETWEENTURNS });

        this.saveDataToBrowserStorage()
    }

    triggerEvent() {
        //let card = (goodEvents.sort(() => 0.5 - Math.random())).slice(0, 1)[0]
        let card = null
        if (this.state.has_good_luck) {
            card = goodEvents[Math.floor(Math.random()*goodEvents.length)]
        } else {
            card = badEvents[Math.floor(Math.random()*badEvents.length)]
        }

        this.setState({ event_card : <EventCardComponent title={card.title} description={card.description} type={card.type} /> });
        this.setState({ event_card_data : card });
        this.setState({ event_modal_show : true });
    }


    eventAcceptCallback() {
        console.log(this.state.event_card_data);
        let tmp = this.state.active_events;
        tmp.push(this.state.event_card_data);
        this.setState({ active_events : tmp });
        this.setState({ event_modal_show : false });

        var tmp_event_data = this.state.event_card_data

        this.setState({ turn_events: [...this.state.turn_events, tmp_event_data]});

        this.saveDataToBrowserStorage()

    }

    eventDenyCallback() {
        this.setState({ event_modal_show : false });
    }

    nextEventCallback() {
        this.triggerEvent()
    }


    getExample() {
        let sample = sampleNarratives[Math.floor(Math.random()*sampleNarratives.length)]
        this.setState({example_narrative : sample});
    }

    resourceModalClose() {
        this.setState({ resource_modal_show : false });
    }

    showResourceModal() {
        this.setState({ resource_modal_show : true });
    }

    setCurrentPhase(e) {
        console.log(e.target.value)
        this.setState({ current_phase : e.target.value })
    }

    onTurnNotesChange(e) {
        console.log(e)
        this.setState({ turn_notes : e.target.value })
    }

    showAddGapModal() {

        this.setState({ gap_desc : "" });
        this.setState({ gap_phase : 1 });
        this.setState({ gap_severity : 1 });

        this.setState({ show_gap_modal : true })
    }

    closeGapModal() {
        this.setState({ show_gap_modal : false })
    }

    onGapTextChange(e) {
        this.setState({ gap_text : e.target.value })
    }

    saveGap() {
        var tmp_gap = {
            gap_desc : this.state.gap_text,
            gap_phase : this.state.gap_phase,
            gap_severity : this.state.gap_severity
        }
        this.setState({ gap_data: [...this.state.gap_data, tmp_gap]});

        this.setState({ gap_text : " " });

        this.saveDataToBrowserStorage()

    }

    onGapPhaseChange(e) {
        this.setState({ gap_phase : e.target.value });
    }

    onGapSeverityChange(e) {
        this.setState({ gap_severity : e.target.value});
    }

    deleteGap(ind) {
           if (window.confirm("Are you sure you want to remove this gap/finding?")) {
            console.log(ind)
            var tmp = this.state.gap_data
            tmp.splice(ind, 1);
            this.setState({ gap_data : tmp });
            this.saveDataToBrowserStorage()
        }

    }



    drawNewCards() {
        this.drawScenarioCards()
        this.saveDataToBrowserStorage()
    }


    getSessionObject() {
        var session_data = {
            cards : {
                vector : this.state.vector,
                persistance : this.state.persistance,
                lateral_traversal : this.state.persistance,
                discovery : this.state.discovery,
                event_cards : this.state.event_cards
            },
            gap_data : this.state.gap_data,
            turn_data : this.state.turn_data
        }
        return session_data
    }

    saveDataToBrowserStorage() {
        localStorage.setItem('session_data', JSON.stringify(this.getSessionObject()));
    }


    findingsBySeverity(sev) {
        var res = 0
        for (let gap of this.state.gap_data) { 
            if (gap.gap_severity == sev) {
                res += 1
            }
        }
        return res
    }

    findingsByPhase(phase) {
        var res = 0
        for (let gap of this.state.gap_data) { 
            console.log(gap.gap_phase)
            if (gap.gap_phase == phase) {
                res += 1
            }
        }
        return res
    }


    exportImage() {
        window.scrollTo(0,0)
        const input = document.getElementById('report_div');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            saveAs(imgData, "InI_TableTop_Report - " + this.props.user.username + " - " + new Date().toDateString() +".png");
        });
    }


    exportPdf() {
        generateInIReport(this.state)
    }

    exportTimeline() {
        const doc = new jsPDF();
        var tableRows = [];
          tableRows.push([
              { content : "Turn Number" },
              { content : "Phase" },
              { content : "Description" },
          ])

          var turns = this.state.turn_data

          for (const i in turns) {
      
                for (const u in turns[i].turn_events) {

                    if (turns[i].turn_events[u] == 0) {
                        //positive
                        tableRows.push([
                            { content : i },
                            { content : getPhaseName(turns[i].turn_phase)},
                            { content : "Positive Event Triggered: " + turns[i].turn_events[u].title},
                        ])  
                    } else {
                        //negative
                        tableRows.push([
                            { content : i },
                            { content : getPhaseName(turns[i].turn_phase)},
                            { content : "Negative Event Triggered: " + turns[i].turn_events[u].title},
                        ])  
                    }
   
                }

              tableRows.push([
                  { content : i },
                  { content : getPhaseName(turns[i].turn_phase)},
                  { content : turns[i].turn_notes},
              ])     
          }
      
      
        doc.text("Incident Tabletop Findings.", 14, 15);
        var finalY = doc.lastAutoTable.finalY || 10
        doc.autoTable({ 
              body : tableRows,
              startY : 20,
          });
          finalY = doc.lastAutoTable.finalY
        doc.save('InI Timeline - ' + this.props.user.username + ' - ' + new Date().toDateString() + '.pdf');


    }

    exportFindings() {
        const doc = new jsPDF();
        var tableRows = [];
          tableRows.push([
              { content : "ID" },
              { content : "Phase" },
              { content : "Severity" },
              { content : "Description" },
          ])

          var gaps = this.state.gap_data

          for (const i in gaps) {
      
              tableRows.push([
                  { content : i },
                  { content : getPhaseName(gaps[i].gap_phase)},
                  { content : getSevName(gaps[i].gap_severity) },
                  { content : gaps[i].gap_desc },
              ])     
          }
      
      
        doc.text("Incident Tabletop Findings.", 14, 15);
        var finalY = doc.lastAutoTable.finalY || 10
        doc.autoTable({ 
              body : tableRows,
              startY : 20,
          });
          finalY = doc.lastAutoTable.finalY
        doc.save('InI Findings - ' + this.props.user.username + ' - ' + new Date().toDateString() + '.pdf');


    }

    render() {
        return(
            <>
            <Page renderToolbar={ err => {
                  return(
                    <Toolbar>
                       <div className="left">
                            <Button onClick={this.showResourceModal}>Resources</Button>
                            <Button style={{ marginLeft : 10 }} onClick={this.setIncidenOver}>Resolve Incident</Button>
                            <Button style={{ marginLeft : 10 }} onClick={this.showAddGapModal}>Gaps and Findings</Button>
                        </div>
                       <div className="center">{"Incidents & Indicators - " + this.props.user.username + " - " + new Date().toDateString()}</div>
                        <div className="right"><LogoutButton logoutHandler={this.props.logoutHandler} /></div>
                    </Toolbar>
                )}} style={{ textAlign : "center" }}>  
                
                 <div style={{ display: "flex", flexWrap: "wrap" }}>

                    

                    {(this.state.game_state === SCENARIOINTRO || this.state.game_state === RESPONSETURN || this.state.game_state == BETWEENTURNS) && (
                        <div style={{ width : "60%"}}> 
                        <Card className="card">
                        <div className="title">Campaign Cards</div>
                        <div className="content">
                            <List>

                                <ListItem>
                                    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns : 'repeat(auto-fill, minmax(240px, 1fr))', width : '100%' }}>
                                        <CardComponent expert_show = {this.state.expert_show_vector} expert_mode={this.state.expert_mode} data={this.state.vector} type={0} />
                                        <CardComponent expert_show = {this.state.expert_show_persistance} expert_mode={this.state.expert_mode} data={this.state.persistance}  type={1} />
                                        <CardComponent expert_show = {this.state.expert_show_lateral_traversal} expert_mode={this.state.expert_mode} data={this.state.lateral_traversal}  type={2} />
                                        <CardComponent expert_mode={this.state.expert_mode} data={this.state.discovery}  type={3} />
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div>
                                                                            <h4>Custom Details</h4>
                                    <p>{this.state.custom_narrative}</p>
                                    </div>

                                </ListItem>
                                <ListItem><h4>Event Cards</h4>
                                    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns : 'repeat(auto-fill, minmax(240px, 1fr))', width : '100%' }}>
                                        {this.state.active_events.map( (val, ind) => {
                                            return(<EventCardComponent title={val.title} description={val.description}  type={val.type}/>)
                                        })}
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                        </Card>
                        </div>
                    )}




                <div style={{ width : "40%" }} >
                    {(this.state.game_state === SCENARIOINTRO) && (
                        <Card className="card">
                        <div className="title">
                            Response
                        </div>
                            <div className="content">
                                <List>
                                    <ListItem>
                                        Create a narrative based on the cards that have been drawn. Once you have established your campaign narrative, click the begin response button.
                                    </ListItem>
                                    <ListItem>
                                        <textarea value={this.state.custom_narrative} onChange={this.onCustomNarrativeChange} style={{ marginLeft : "5%", width : "75%" }} className="textarea" rows="3" placeholder="Enter custom details if required" />
                                    </ListItem>
                                    <ListItem>
                                        <Button style={{ marginRight : 10 }} onClick={this.introComplete}>Begin Response</Button>
                                        <Button onClick={this.drawNewCards}>Redraw Cards</Button>
                                    </ListItem>
                                    <ListItem>Need inspiration? Click this button to get an example narrative!<Button onClick={this.getExample}><Icon icon="fa-book-dead" /></Button></ListItem>
                                    <ListItem>{this.state.example_narrative}</ListItem>
                                </List>
                            </div>
                            </Card>
                    )}
                    {(this.state.game_state === RESPONSETURN) && (
                                            <Card className="card">
                                            <div className="title">
                                                Response
                                            </div>
                            <div className="content">
                                <List>
                                    <ListItem>
                                        Discuss the actions you would like to take and document them.
                                    </ListItem>
                                    <ListItem>
                                        <div style={{ width : "100%", display: "flex", flexWrap: "wrap"}}>
                                        <select
                                            style={{ width : "20%", height : 50}}
                                            value={this.state.current_phase}
                                            onChange={(event) => this.setCurrentPhase(event)}>
                                            <option value={1}>PREPARE</option>
                                            <option value={2}>DETECT AND ASSES</option>
                                            <option value={3}>CONTAIN</option>
                                            <option value={4}>ERADICATE</option>
                                            <option value={5}>RECOVER</option>
                                            <option value={6}>REVIEW</option>
                                        </select>
                                        <textarea onChange={this.onTurnNotesChange} style={{ marginLeft : "5%", width : "75%" }} className="textarea" rows="3" placeholder="Textarea"></textarea>

                                        </div>


                                    </ListItem>
                                    <ListItem>
                                        <center>
                                            <div><Button onClick={this.endResponseTurn}>Complete Turn!</Button></div>
                                        </center>
                                    </ListItem>
                                </List>
                            </div>
                            </Card>
                    )}
                    {(this.state.game_state === BETWEENTURNS) && (
                                        <Card className="card">
                                        <div className="title">
                                            Response
                                        </div>
                            <div className="content">
                                <List>
                                    <ListItem>
                                        This turns roll:  {this.state.current_roll}
                                    </ListItem>
                                    <ListItem>
                                        
                                        {(this.state.has_good_luck) && (
                                            "You have had a good roll!!. Any event you trigger will be positive!"
                                        )}
                                        {(!this.state.has_good_luck) && (
                                            "You have had a bad roll!!. Any event you trigger will be negative!" 
                                        )}

                                    </ListItem>
                                        <ListItem>
                                            <div style={{ display: "flex", flexWrap: "wrap"}}>
                                                
                                                <Button style={{ marginRight : 10 }} onClick={this.setNextTurn}>Next Turn!</Button>
                                                <Button style={{ marginRight : 10 }} onClick={this.triggerEvent}>Trigger Event</Button>
                                                
                                            </div>

                                        </ListItem>
                                </List>
                            </div>
                            </Card>
                    )}
                    


                    {(this.state.game_state === SCENARIOINTRO || this.state.game_state === BETWEENTURNS || this.state.game_state === RESPONSETURN) && (

                        <Card>
                            <div className="title">
                                Incident Timeline
                            </div>
                            <div className="content">
                                <div style={{overflowY : "auto"}}>
                                <table>
                                    <tr>
                                        <th>Turn</th>
                                        <th>Phase</th>
                                        <th>Notes</th>
                                    </tr>

                                    {this.state.turn_data.map((turn, index) => (
                                        <>
                                        
                                        {turn.turn_events.map((event, index) => (
                                            <tr>
                                                <td></td>
                                                <td></td><td>
                                                    {(event.type == 0) && (
                                                        <b style={{ color : "green" }}>Positive Event Triggered: {event.title}</b>
                                                    )}
                                                    {(event.type == 1) && (
                                                        <b style={{ color : "red" }}>Negative Event Triggered: {event.title}</b>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}

                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{turn.turn_phase_desc}</td>
                                            <td>{turn.turn_notes}</td>
                                        </tr>

                                        </>
                                    ))}
                                    </table> 
                                </div>
                            </div>
                        </Card>


                    )}
                    </div>


                    {(this.state.game_state == IRBREIFING) && (
                       <div id="briefing_div" style={{ width : "100%"}}>

                            <Card>
                                <div className="title">
                                    <h3>Briefing</h3>
                                </div>
                                <div className="content">
                                <List>

                                    <ListItem>

                                        <div>
                                            <h3>The tabletop process</h3>
                                            <p>This exercise will be be broken down into a few descrete phases:
                                                <List>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Breifing</h4>
                                                            <p>
                                                                The first part of running this exercise is for the person leading the exercise to discuss the upcoming exercise with the team, including: what to expect, how team members can contribute, the objectives, and how it will flow.
                                                                This is also an opportunity to provide a refresher on core incident response processes and concepts including:
                                                                <ul>The phases of incident response</ul>
                                                                <ul>Organisation specific incdient response policies, processes, and standards</ul>
                                                                <ul>Core concepts such as "Assume Breach", "Zero Trust", "Fail Closed", and "Chain of Custody", amoung others.</ul> 

                                                                Once the briefing is completed, any questions or concerns from the team should be addressed before progressing the exercise with the "Begin Exercise" button.
                                                            </p> 
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Generate Scenario</h4>
                                                            <p>
                                                                Based on the cards drawn at the start of the game. The person leading the exercise will develop a scenario and communicate it to the team members.
                                                                In some cases, additional detail that tailors the scenario to your organisation may be included. 
                                                            </p> 
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Turns and Post-turn Reviews</h4>
                                                            <p>
                                                                Once started, the exercise will progress as a series of turns and post-turn review sections. At the start of each turn, the person leading 
                                                                the exercise will outline the current situation and then ask the team members to provide input as to what actions to take.
                                                                These actions will be documented in the timeline.
                                                                Following each turn, there will be an opportunity to discuss that turn and potentially trigger an event (Either positive or negative) that will
                                                                affect future turns. This is also an opportuniy to consider the actions that were taken during the turn and add any applicable findings to the gaps and findings register.
                                                            
                                                                Turns will continue in this sequence until the incident is deemed resolved and all nessecary actions have been completed. 
                                                            </p> 
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Resolve Incident</h4>
                                                            <p>
                                                                Once the scenario is deemed resolved, the person leading the exercise will click the "Resolve Incident" button on the top toolbar which will
                                                                generate a report outlining the scenario, timeline, and findings.   
                                                            </p> 
                                                        </div>
                                                    </ListItem>  

                                                    <ListItem>
                                                        <Button onClick={this.startExercise}>Begin Exercise
                                                        </Button></ListItem>                                                  
                                                </List>

                                            </p>
                                        </div>

                                    </ListItem>

                                </List>

                                </div>
                            </Card>

                        </div>
                    )}

                    {(this.state.game_state == GAMEOVER) && (
                        <div id="report_div" style={{ width : "100%"}}>
                            <Card>
                            <div className="title" style={{ marginBottom : 25}}>
                                    Tabletop Exercise Report - {this.props.user.username} - {new Date().toDateString()}
                                   <div style={{float : "right"}}>
                                        <Button style={{ marginRight : 10}} onClick={this.exportImage}>Export As Image</Button>
                                        <Button style={{ marginRight : 10}}onClick={this.exportFindings}>Export Findings</Button>
                                        <Button onClick={this.exportTimeline}>Export Timeline</Button>
                                    </div>
    
                            </div>
                            <div style={{ width : "100%" }} className="content">
                                <List>
                                    <ListItem>
                                        <Card id="report_exec_summary" style={{ width : "100%" }}>

                                            <div className="title">
                                                Executive Summary                                                
                                            </div>
                                            <div className="content">
                                                {getReportDefaults().introduction}
                                                {getReportDefaults().objective}
                                                {getReportDefaults().scenario}
                                                    <Card>
                                                        <b>{this.state.discovery.title}</b>
                                                        <p><code>{this.state.discovery.description}</code></p>
                                                    </Card>
                                                {getReportDefaults().custom_narrative}
                                                    <Card>
                                                        <code>{this.state.custom_narrative}</code>
                                                    </Card>


                                                <h2>Findings Summary</h2>
                                                <List>
                                                    <ListItem>
                                                        The exercise took: {this.state.turn_data.length} turns to complete and a total of {this.state.gap_data.length} findings were identified.
                                                    </ListItem>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Findings by Severity</h4>
                                                            
                                                            <table>
                                                                <tr>
                                                                    <th>Severity</th>
                                                                    <th>Count</th>
                                                                </tr>
                                                                <tr style={{ backgroundColor: "#007F0E"}}>
                                                                    <td>OFI</td>
                                                                    <td>{this.findingsBySeverity(1)}</td>
                                                                </tr>
                                                                <tr style={{ backgroundColor: "#FF6A00"}}>
                                                                    <td>Minor</td>
                                                                    <td>{this.findingsBySeverity(2)}</td>
                                                                </tr>
                                                                <tr style={{ backgroundColor: "#FF0000"}}>
                                                                    <td>Major</td>
                                                                    <td>{this.findingsBySeverity(3)}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                    <div>
                                                            <h4>Findings by Incident Phase</h4>
                                                            
                                                            <table>
                                                                <tr>
                                                                    <th>Phase</th>
                                                                    <th>Count</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Prepare</td>
                                                                    <td>{this.findingsByPhase(1)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Detect and Asses</td>
                                                                    <td>{this.findingsByPhase(2)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Contain</td>
                                                                    <td>{this.findingsByPhase(3)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Eradicate</td>
                                                                    <td>{this.findingsByPhase(4)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Recover</td>
                                                                    <td>{this.findingsByPhase(5)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Review</td>
                                                                    <td>{this.findingsByPhase(6)}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                        <div>
                                                            <h4>Findings</h4>
                                                            <table>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Phase</th>
                                                                    <th>Severity</th>
                                                                    <th>Description</th>
                                                                    
                                                                </tr>
                                                                {this.state.gap_data.map((gap, index) => (
                                                                        <tr key={index}>
                                                                            <td style={{ textAlign : "center" }}>{index}</td>
                                                                            <td style={{ textAlign : "center" }}>{getPhaseName(gap.gap_phase)}</td>
                                                                            <td style={{ textAlign : "center" }}>{getSevName(gap.gap_severity)}</td>
                                                                            <td>{gap.gap_desc}</td>
                                                                         
                                                                        </tr>

                                                                    ))}
                                                            </table>
                                                        </div>
                                                    </ListItem>
                                                </List>                       
                                            </div>
                                        </Card>
                                        </ListItem><ListItem>
                                        <Card style={{ width : "100%" }}>
                                            <div className="title">
                                                Scenario Details                                              
                                            </div>
                                            <div className="content">
                                            <p>This section will describe the specific tactics and techniques that were identified as part of this scenario. </p>

                                            <List>
                                                <ListItem>
                                                    <b>Custom Scenario Details:</b>
                                                    <p>{this.state.custom_narrative}</p>
                                                </ListItem>
                                                    <ListItem>
                                                    <b>Inital Vector: {this.state.vector.title}</b> 
                                                    <p>{this.state.vector.description}</p>
                                                    </ListItem>
                                                    <ListItem>
                                                    <b>Maintaining a persistent presence: {this.state.persistance.title}</b> 
                                                    <p>{this.state.persistance.description}</p>
                                                    </ListItem>
                                                    <ListItem>
                                                    <b>Moving Laterally: {this.state.lateral_traversal.title}</b> 
                                                    <p>{this.state.lateral_traversal.description}</p>
                                                    </ListItem>
                                                </List>
                                            <p>{this.state.custom_narrative}</p>
                                                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns : 'repeat(auto-fill, minmax(240px, 1fr))', width : '100%' }}>
                                                    <CardComponent expert_show = {this.state.expert_show_vector} expert_mode={this.state.expert_mode} data={this.state.vector} type={0} />
                                                    <CardComponent expert_show = {this.state.expert_show_persistance} expert_mode={this.state.expert_mode} data={this.state.persistance}  type={1} />
                                                    <CardComponent expert_show = {this.state.expert_show_lateral_traversal} expert_mode={this.state.expert_mode} data={this.state.lateral_traversal}  type={2} />
                                                    <CardComponent expert_mode={this.state.expert_mode} data={this.state.discovery}  type={3} />
                                                </div>
                                                <p>Additionally these were the events that were triggered as part of the tabletop exercise:</p>
                                                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns : 'repeat(auto-fill, minmax(240px, 1fr))', width : '100%' }}>
                                                    {this.state.active_events.map( (val, ind) => {
                                                        return(<EventCardComponent title={val.title} description={val.description}  type={val.type}/>)
                                                    })}
                                                </div>
                                            </div>
                                        </Card>
                                    </ListItem>
                                    <ListItem>

                                        <Card style={{ width : "100%" }}>
                                            <div className="title">
                                                Incident Timeline                                               
                                            </div>
                                            <div className="content">
                                            <table>
                                                <tr>
                                                    <th>Turn</th>
                                                    <th>Phase</th>
                                                    <th>Notes</th>
                                                </tr>

                                                {this.state.turn_data.map((turn, index) => (
                                                    <>
                                                    
                                                    {turn.turn_events.map((event, index) => (
                                                        <tr>
                                                            <td></td>
                                                            <td></td><td>
                                                                {(event.type == 0) && (
                                                                    <b style={{ color : "green" }}>Positive Event Triggered: {event.title}</b>
                                                                )}
                                                                {(event.type == 1) && (
                                                                    <b style={{ color : "red" }}>Negative Event Triggered: {event.title}</b>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    <tr key={index}>
                                                        <td>{index}</td>
                                                        <td>{turn.turn_phase_desc}</td>
                                                        <td>{turn.turn_notes}</td>
                                                    </tr>

                                                    </>
                                                ))}
                                                </table> 

                                            </div>
                                        </Card>
                                    </ListItem>
                                </List>
                            </div>

                            </Card>
                        </div>
                    )}


                    

                    <Modal classNames={{modal: 'customEventModal'}} open={this.state.event_modal_show} onClose={this.eventDenyCallback} center>
                            <div>
                                <center>
                                   {this.state.event_card} 
                                </center>
                                
                                <Button style={{ marginRight : 10 }} onClick={this.eventAcceptCallback}>Accept Event</Button>
                                <Button style={{ marginRight : 10 }} onClick={this.nextEventCallback}>New Event</Button>
                                <Button style={{ marginRight : 10 }} onClick={this.eventDenyCallback}>Nevermind</Button>
                             </div>
                    </Modal>


                    <Modal classNames={{modal: 'customEventModal'}} closeIcon={(<Button>Close</Button>)} open={this.state.show_gap_modal} onClose={this.closeGapModal} center>
                            <div style={{ color : "#fff", width : "100%"}}>
               
                                        <h2>New Gap/Finding</h2>
                                        <div style={{ padding : 10, width : "100%"}}>
                                            <div>
                                            Phase
                                                <select

                                                    value={this.state.gap_phase}
                                                    onChange={(event) => this.onGapPhaseChange(event)}>
                                                    <option value={1}>PREPARE</option>
                                                    <option value={2}>DETECT AND ASSES</option>
                                                    <option value={3}>CONTAIN</option>
                                                    <option value={4}>ERADICATE</option>
                                                    <option value={5}>RECOVER</option>
                                                    <option value={6}>REVIEW</option>
                                                </select>
                                            </div>
                                            <div>
                                            Severity
                                                <select
                                                    style={{ textAlign : "center" }}
                                                    value={this.state.gap_severity}
                                                    onChange={(event) => this.onGapSeverityChange(event)}>
                                                    <option value={1}>OFI</option>
                                                    <option value={2}>Minor</option>
                                                    <option value={3}>Major</option>
                                                </select>
                                            </div>

                                        </div>

                                        <textarea  style={{padding : 10, width : "100%"}} value={this.state.gap_text} onChange={this.onGapTextChange} className="textarea" rows="3" placeholder="Textarea" />
                                        <center>
                                            <Button style={{ marginTop: 10 }} onClick={this.saveGap}>Save Gap</Button>
                                        </center>
                                        
              
                                        <h2>List of Gaps/Findings</h2>

                                        <div style={{ width : "100%"}}>

                                            <table>
                                            <tr>
                                                <th>ID</th>
                                                <th>Phase</th>
                                                <th>Severity</th>
                                                <th>Description</th>
                                                <th>Actions</th>
                                            </tr>

                                            {this.state.gap_data.map((gap, index) => (
                                                <tr key={index}>
                                                    <td style={{ textAlign : "center" }}>{index}</td>
                                                    <td style={{ textAlign : "center" }}>{getPhaseName(gap.gap_phase)}</td>
                                                    <td style={{ textAlign : "center" }}>{getSevName(gap.gap_severity)}</td>
                                                    <td>{gap.gap_desc}</td>
                                                    <td><Button onClick={() => this.deleteGap(index)}>Delete</Button></td>
                                                  </tr>

                                            ))}
                                            

                                        </table>  
                                        </div>
               

                                
                               
                             </div>
                    </Modal>                        

                    <Modal classNames={{modal: 'customModal'}} closeIcon={(<Button>Close</Button>)} open={this.state.resource_modal_show} onClose={this.resourceModalClose} center>
                        <div className={"resourceBackground"} style={{ width : "100%" }}>
                        
                            <Tabs>
                                <TabList>
                                <Tab>Phases of Incident Response</Tab>
                                <Tab>External Resources</Tab>
                                <Tab>Glossary of Terms</Tab>
                                <Tab>Concepts</Tab>
                                </TabList>

                                <TabPanel>
                                    <h2>
                                        <DocPhases />
                                    </h2>
                                </TabPanel>
                                <TabPanel>
                                    <h2>
                                        <DocExternalResources />
                                    </h2>
                                </TabPanel>
                                <TabPanel>
                                    <h2>
                                        <DocGlossary />
                                    </h2>
                                </TabPanel>
                                <TabPanel>
                                    <h2>
                                        <Card>
                                            <div className="title" style={{ color : "#fff" }}><h2>Concepts</h2></div>
                                            <div className="content">

                                                <List>
                                                    <ListItem>
                                                        <div className="title">
                                                            <h4>Assume Breach/Fail Closed</h4><br/>
                                                        </div>
                                                        <div className="content">
                                                            <p>
                                                                The concept of fail closed (and by extension the concept of "assume breach") requires that when an organization is designing controls and defining response actions
                                                                with the understanding that when something fails such as a security control or a response process, it should fail in a way that reduces the potential impact as much as possible. 
                                                            </p>
                                                        </div>
                                                    </ListItem>
                                                    <ListItem>
                                                        <div className="title">
                                                            <h4>Trust Model/Zero Trust</h4><br/>
                                                        </div>
                                                        <div className="content">
                                                            <p>
                                                                A trust model (Of which Zero Trust is one) assumes a certain risk of malicious activity based on a system or network. In the case of Zero Trust, it is assumed that any asset is operating in a hostile envrioment at all times.
                                                                This type of model helps develop security controls that are sufficently resilent to the potential compromise of privileged assets. 
                                                            </p>
                                                        </div>
                                                    </ListItem>
                                                </List>

                                            </div>
                                        </Card>
                                    </h2>
                                </TabPanel>
                            </Tabs>
                            </div>
                    </Modal>
                        </div>
            </Page>
            </>

        );
    }

}

export default HostMain;