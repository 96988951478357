import React, { useEffect, useRef } from 'react';

//adapted from this dude: https://pablo.gg/en/blog/coding/creating-a-matrix-source-code-effect-background-for-my-website-with-react/

const MatrixBackground = (props) => {
  const canvas = useRef();

  useEffect(() => {
    const context = canvas.current.getContext('2d');
    const width = window.innerWidth;
    const height = window.innerHeight;
    const font_size = 10;
    const columns = Math.floor(width / font_size);
    const yPositions = Array.from({ length: columns }).fill(0);

    canvas.current.width = width;
    canvas.current.height = height;

   

    const matrixEffect = () => {
        context.fillStyle = '#0001';
        context.fillRect(0, 0, width, height);
        //D0396A
        //context.fillStyle = '#0f0';
        context.fillStyle = "#D0396A";

        context.font = font_size + 'pt monospace';
        yPositions.forEach((y, index) => {
            const text = String.fromCharCode(Math.random() * 128);
            const x = index * font_size;
            context.fillText(text, x, y);


            if (y > 200 + Math.random() * 10000) {
                yPositions[index] = 0;
            } else {
                yPositions[index] = y + font_size;
            }
        });
    };

    const interval = setInterval(matrixEffect, 50);
    return () => {
        clearInterval(interval);
    };
}, [canvas]);


  return (
    <>
      <canvas style={{
          dispaly : 'block' ,
          background: '#000000',
          overflow: 'hidden',
          position: 'fixed',
          height: '100%',
          width: '100%',
          zIndex : '0',
          left: '0',
          top: '0',
          }} 
        ref={canvas}></canvas>
    </>
  );
  

}

export default MatrixBackground;
