import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

import getReportDefaults from "./reportDefaults";


function generateInIReport(state) {
    const doc = new jsPDF();

    var reportRows = []

    reportRows.push([{ content : (getReportDefaults().introduction)}])
    reportRows.push([{ content : (getReportDefaults().objective)}])
    reportRows.push([{ content : (getReportDefaults().scenario)}])

    doc.text("Incident and Indicators Tabletop Exercise Report", 14, 15);
    var finalY = doc.lastAutoTable.finalY || 10
    doc.autoTable({ 
          body : reportRows,
          startY : 20,
      });
      finalY = doc.lastAutoTable.finalY
    doc.save(`export_test.pdf`);



}

export default generateInIReport;
