import {InitalVectorDeck, PersistanceDeck, LateralTraversalDeck, DiscoveryDeck} from './CardData';


function getCardFromTitle(title, type) {
    let deck = [];
    if (type === "vector") {
        deck = InitalVectorDeck;
    } else if (type === "persistance") {
        deck = PersistanceDeck;
    } else if (type === "lateral_traversal") {
        deck = LateralTraversalDeck;
    } else if (type === "discovery") {
        deck = DiscoveryDeck
    }


    for (let card of deck) {
        if (card.title === title) {
            return card
        }
    }

    return null;
}


function diceRoll() {
    return Math.floor( Math.random() * 20 ) + 1
}


export {getCardFromTitle, diceRoll}