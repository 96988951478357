import React from 'react';
import ons from 'onsenui';
import { Input, Switch, Tabbar, Popover, List, Tab, Icon, Card, Page, Toolbar, Button, Navigator, Template, Splitter, Col, Row, ListItem } from 'react-onsenui';

import './App.css';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/dark-onsen-css-components.css';


import HostMain from './components/host/HostMain'
import MatrixBackground from './components/MatrixBackground';


class App extends React.Component {
  constructor(props) {
    super(props);
    

    this.user = {
      is : false,
      username : ""
    };

    this.renderPage = this.renderPage.bind(this);
    this.updateUsername = this.updateUsername.bind(this);
    this.onRegisterSubmit = this.onRegisterSubmit.bind(this);
    this.logoutCallback = this.logoutCallback.bind(this);

    this.onExpChange = this.onExpChange.bind(this);
    
    this.state = {
      authenticated : false,

      expert_mode : false,

      expert_pop_open : false,

      pop_target : null,

    }

  }



  componentDidMount() {
    console.log("App component mounted.");
    //Setup the event monitor for auth events

    if (this.user.is) {
         this.setState({ authenticated : true });
    }

  }

  updateUsername(e) {
    this.user.username = e.target.value
  }

  onRegisterSubmit(e) {
      this.setState({ authenticated : true });
  }


  onExpChange(e) {
    console.log(e)
    console.log(e.detail.value)
  }
 
  logoutCallback(e) {

    if (window.confirm("Are you sure you want to end this session?")) {
      console.log("User logout");
      this.setState({ authenticated : false });
      this.user = {
        is : false,
        username : ""
      };
    }


  }



  renderPage(route, navigator) {
    route.props = route.props || {};
    route.props.navigator = navigator;
    return React.createElement(route.comp, route.props);
  }

  render() {
    return (
      <>
      {!this.state.authenticated && (
        <Page style={{textAlign : 'center'}}>
          <MatrixBackground />    
          <Card className="center" style={{ position: 'absolute', bottom: '50%', right : '50%',  transform: 'translateX(50%)'}}>
            <div className="content">
              <List>
                <ListItem>
                  <Input placeholder="Team Name?" float onInput={this.updateUsername} />
                  <Button onClick={this.onRegisterSubmit}><Icon icon="fa-play"/></Button>
                </ListItem>

                {/*<ListItem>
                  <div>
                    Hide cards on start?
                    <Switch style={{ marginLeft : 20}} checked={this.state.expert_mode} onChange={this.onExpChange} />
                  </div>
                </ListItem>*/}

              </List>
            </div>
          </Card>
          <div  style={{ opacity: 0.2, position: 'absolute', bottom: '5%', right : '50%',  transform: 'translateX(50%)'}}>
            <h2>Incidents and Indicators</h2>
            <p> No data entered into this application will be saved or even sent to a server/endpoint, all data is stored in your brower either within JS variables or within your browsers local storage. 
              This means that if you close the window, you will lose all progress, however you may potentially recover gaps and timeline data from your browser storage if required though it will be overwritten once you start a new exercise.</p>
          </div>
        </Page>
      )}
      {this.state.authenticated && (

          <>
            <MatrixBackground /> 
            <HostMain  user={this.user} expert_mode={this.state.expert_mode} logoutHandler={this.logoutCallback} />  
          </>

      )}
      </>
    );
  }

}

export default App;
